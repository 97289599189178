import type {
  PaginatedShoppingListInfo,
  PaginationParameters,
  ShoppingListInfoStatus,
} from '../domain/membership.interface'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

export interface GetMembershipShoppingListsUsecase {
  (
    membershipNumber: string,
    paginationParameters?: PaginationParameters,
    status?: ShoppingListInfoStatus | 'ALL',
  ): Promise<PaginatedShoppingListInfo | null>
}

export function createGetMembershipShoppingListsUsecase({
  shoppingListRepository,
}: Dependencies): GetMembershipShoppingListsUsecase {
  return async (membershipNumber, paginationParameters, status) => {
    let shoppingListInfoStatus
    if (status !== 'ALL') shoppingListInfoStatus = status
    const paginatedShoppingLists = await shoppingListRepository.fetchShoppingLists(
      membershipNumber,
      paginationParameters,
      shoppingListInfoStatus,
    )
    return paginatedShoppingLists
  }
}

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}
