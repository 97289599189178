export default {
  namespaced: true,
  state: {
    membershipsListError: null,
  },
  getters: {
    getMembershipsListError(state) {
      return state.membershipsListError
    },
  },
  mutations: {
    SET_MEMBERSHIPS_LIST_ERRORS(state, membershipsListError) {
      state.membershipsListError = membershipsListError
    },
  },
  actions: {
    setMembershipsListError({ commit }, { membershipsListError }) {
      commit('SET_MEMBERSHIPS_LIST_ERRORS', membershipsListError)
    },
  },
}
