import isEmpty from 'lodash/isEmpty'
import toInteger from 'lodash/toInteger'
import toString from 'lodash/toString'
import { mapToApi as mapCooperativeToApi, mapFromApi as mapCooperativeFromApi } from './cooperative.mapper'

export function mapFromApi(profile = {}) {
  const {
    id,
    email,
    company_name: companyName,
    membership_number: membershipNumber,
    cooperative,
    payment_methods: paymentMethods = [],
    technical_sales: technicalSales = [],
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    siret_number: siretNumber,
    valid_certiphyto: validCertiphyto,
    certiphyto_end_date: certiphytoEndDate,
    crops,
  } = profile

  if (isEmpty(toString(id))) return {}

  return {
    id: toString(id),
    email,
    companyName,
    membershipNumber,
    cooperative: mapCooperativeFromApi(cooperative),
    paymentMethods,
    technicalSales,
    firstName,
    lastName,
    phoneNumber,
    siretNumber,
    validCertiphyto,
    certiphytoEndDate,
    crops,
  }
}

export function mapToApi(profile = {}) {
  const {
    id,
    email,
    firstName,
    lastName,
    phoneNumber,
    companyName,
    membershipNumber,
    siretNumber,
    validCertiphyto,
    cooperative,
    paymentMethods,
    technicalSales,
    certiphytoEndDate,
  } = profile

  if (isEmpty(toString(id))) return {}

  return {
    id: toInteger(id),
    email,
    company_name: companyName,
    membership_number: membershipNumber,
    cooperative: mapCooperativeToApi(cooperative),
    technical_sales: technicalSales,
    payment_methods: paymentMethods,
    first_name: firstName,
    last_name: lastName,
    phone_number: phoneNumber,
    siret_number: siretNumber,
    valid_certiphyto: validCertiphyto,
    certiphyto_end_date: certiphytoEndDate,
  }
}
