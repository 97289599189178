import type { ShoppingListApi, ShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { ShoppingListStatusApi } from '@aladin/shared-shopping-list-sdk'
import type { MembershipService as MembershipDataSource } from '@b2ag/membership/src/services/membership.service'
import { v4 as uuidv4 } from 'uuid'
import { i18n } from '@b2ag/locale'
import { mapShoppingListToList, mapToTargetMembership, mapToTargetShoppingList } from './target-membership.mappers'
import type {
  CreatedTargetShoppingList,
  CurrentShoppingListWithMembership,
  CurrentTargetShoppingList,
  TargetMembership,
} from '../domain/target-membership.interface'
import { MembershipDoesNotExistError } from '../domain/membership-does-not-exist-error'

export interface TargetMembershipRepository {
  fetchTargetMembershipWithShoppingList: (
    partnerId: number,
    membershipNumber: string,
    shoppingListId: string,
  ) => Promise<CurrentShoppingListWithMembership>
  fetchTargetMembership: (partnerId: number, membershipNumber: string) => Promise<TargetMembership>
  fetchTargetShoppingList: (
    membershipNumber: string,
    shoppingListId?: string,
  ) => Promise<CurrentTargetShoppingList | null>
  createNewTargetShoppingList: (partnerId: number, membershipNumber: string) => Promise<CreatedTargetShoppingList>
  fetchDefaultTargetMembership: ({
    partnerId,
    technicianUid,
  }: {
    partnerId: number
    technicianUid: string
  }) => Promise<CurrentShoppingListWithMembership | null>
}

interface Dependencies {
  membershipDataSource: MembershipDataSource
  shoppingListDataSource: ShoppingListApiClient
}

export function createTargetMembershipRepository(deps: Dependencies): TargetMembershipRepository {
  async function fetchTargetMembershipWithShoppingList(
    partnerId,
    membershipNumber,
    shoppingListId,
  ): Promise<CurrentShoppingListWithMembership> {
    const [membershipApi, shoppingListApi] = await Promise.all([
      await deps.membershipDataSource.get(partnerId, membershipNumber),
      shoppingListId ? await deps.shoppingListDataSource.fetchOwnedShoppingList(shoppingListId) : Promise.resolve(null),
    ])
    return {
      membership: mapToTargetMembership(membershipApi),
      shoppingList: shoppingListApi && mapToTargetShoppingList(shoppingListApi),
      shoppingListToList: shoppingListApi && mapShoppingListToList(shoppingListApi),
    }
  }

  async function fetchTargetMembership(partnerId, membershipNumber): Promise<TargetMembership> {
    try {
      const membershipApi = await deps.membershipDataSource.get(partnerId, membershipNumber)
      return mapToTargetMembership(membershipApi)
    } catch (error: any) {
      if (error.message.includes('403')) {
        throw new MembershipDoesNotExistError()
      } else {
        throw error
      }
    }
  }

  async function fetchDefaultTargetMembership({
    partnerId,
    technicianUid,
  }): Promise<CurrentShoppingListWithMembership | null> {
    const membershipsData = await deps.membershipDataSource.list(partnerId, technicianUid)
    if (membershipsData.memberships.length === 0) {
      return null
    }
    const defaultMembershipApi = membershipsData.memberships[0]

    let defaultShoppingListApi: ShoppingListApi | undefined
    const defaultMembershipPendingShoppingListApi = await deps.shoppingListDataSource.fetchOwnedShoppingLists({
      membershipNumbers: [defaultMembershipApi.membership_number],
      status: ShoppingListStatusApi.pending,
    })
    if (defaultMembershipPendingShoppingListApi.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      defaultShoppingListApi = defaultMembershipPendingShoppingListApi[0]
    }
    return {
      membership: mapToTargetMembership(defaultMembershipApi),
      shoppingList: defaultShoppingListApi ? mapToTargetShoppingList(defaultShoppingListApi) : null,
      shoppingListToList: defaultShoppingListApi ? mapShoppingListToList(defaultShoppingListApi) : null,
    }
  }

  /*
    XJU - 12/10/2023 : Peut être refactoré avec fetchShoppingList dans le service shopping-list.service.ts
  */
  async function fetchTargetShoppingList(
    membershipNumber: string,
    shoppingListId?: string, // temporaire pour aller chercher une shopping list en particulier
  ): Promise<CurrentTargetShoppingList | null> {
    const filterParams = shoppingListId
      ? undefined
      : {
          page: 1,
          limit: 10,
        }

    const shoppingLists = await deps.shoppingListDataSource.fetchOwnedShoppingLists(
      {
        membershipNumbers: [membershipNumber],
      },
      filterParams,
    )
    if (shoppingLists.length === 0) return null

    const targetShoppingList = defaultShoppingList(shoppingLists, shoppingListId)
    if (targetShoppingList) {
      return {
        shoppingList: mapToTargetShoppingList(targetShoppingList),
        shoppingListToList: mapShoppingListToList(targetShoppingList),
      }
    }
    return null
  }

  async function createNewTargetShoppingList(partnerId, membershipNumber): Promise<CreatedTargetShoppingList> {
    try {
      const membership = await deps.membershipDataSource.get(partnerId, membershipNumber)
      const newShoppingList = await deps.shoppingListDataSource.saveShoppingList({
        partner_id: partnerId,
        recipient: {
          membership_number: membershipNumber,
          company_name: membership.company_name,
          first_name: membership.first_name,
          last_name: membership.last_name,
          phone_number: membership.phone_number,
          email: membership.email,
          isRegistered: membership.registered,
        },
        lines: [],
        comment: '',
        title: `${i18n._('Liste')} ${uuidv4().substring(0, 6).toUpperCase()}`,
      })
      return {
        shoppingList: mapToTargetShoppingList(newShoppingList),
        shoppingListToList: mapShoppingListToList(newShoppingList),
      }
    } catch (error: any) {
      if (error.message.includes('403')) {
        throw new MembershipDoesNotExistError()
      } else {
        throw error
      }
    }
  }

  return {
    fetchTargetMembershipWithShoppingList,
    fetchTargetMembership,
    createNewTargetShoppingList,
    fetchTargetShoppingList,
    fetchDefaultTargetMembership,
  }
}

function defaultShoppingList(shoppingLists: ShoppingListApi[], shoppingListId?: string) {
  return shoppingListId
    ? shoppingLists.find((shopList) => shopList.id === shoppingListId)
    : shoppingLists.find((shopList) => shopList.status === 'pending')
}
