const AdnStatusOfferEnum = {
  ACTIVE: 'offer-active',
  UPCOMING: 'offer-upcoming',
  PAST: 'offer-past',
  DELETED: 'offer-deleted',
}
const AdnStatusOrderEnum = {
  PENDING: 'order-pending',
  ACCEPTED: 'order-accepted',
  FULFILLED: 'order-fulfilled',
  CANCELLED: 'order-cancelled',
  VALIDATING: 'order-validating',
}
const AdnStatusLineItemEnum = {
  PENDING: 'line-item-pending',
  TRANSIT: 'line-item-transit',
  FULFILLED: 'line-item-fulfilled',
}

const AdnStatusDiscountEnum = {
  ACTIVE: 'discount-active',
  UPCOMING: 'discount-upcoming',
  PAST: 'discount-past',
  ARCHIVED: 'discount-archived',
}

export const AdnStatusEnum = {
  OFFER: { ...AdnStatusOfferEnum },
  DISCOUNT: { ...AdnStatusDiscountEnum },
  ORDER: { ...AdnStatusOrderEnum },
  LINE_ITEM: { ...AdnStatusLineItemEnum },
}

export function getAllStatus() {
  return Object.values(AdnStatusEnum).reduce((accumulator: string[], value): string[] => {
    const status = Object.values(value)
    return accumulator.concat(status)
  }, [])
}
