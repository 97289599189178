import type { ShoppingListInfo } from '../domain/membership.interface'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

export interface GetMembershipShoppingListsSummaryUsecase {
  (membershipNumber: string): Promise<ShoppingListInfo[] | null>
}

export function createGetMembershipShoppingListsSummaryUsecase({
  shoppingListRepository,
}: Dependencies): GetMembershipShoppingListsSummaryUsecase {
  return async (membershipNumber) => {
    const last3ShoppingLists = await shoppingListRepository.fetchShoppingLists(membershipNumber, { page: 1, limit: 3 })
    if (last3ShoppingLists === null || last3ShoppingLists.results === null) return null
    return last3ShoppingLists?.results
  }
}

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}
