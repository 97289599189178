import type { AladinError } from '@aladin/shared-error'
import type { ShoppingList } from './shopping-list.model'

const CERTIFICATE_ERROR_KEY = 'certificate.errors.invalid'

interface CertificateError extends AladinError {
  details: {
    certificate: { name: string; isValid: boolean }
    product: { isRestrictedExplosivesPrecursor: boolean; isRestrictedCertiphyto: boolean }
  }
}

export function hasCertiphytoInvalidError(shoppingList: ShoppingList) {
  return !!shoppingList?.errors?.find(
    // @ts-expect-error à voir, chercher pourquoi
    (error: CertificateError) =>
      error.key === CERTIFICATE_ERROR_KEY &&
      error?.details?.product?.isRestrictedCertiphyto &&
      !error?.details?.certificate.isValid,
  )
}

export function hasExplosivePrecursorInvalidError(shoppingList: ShoppingList) {
  return !!shoppingList?.errors?.find(
    // @ts-expect-error à voir, chercher pourquoi
    (error: CertificateError) =>
      error.key === CERTIFICATE_ERROR_KEY &&
      error?.details?.product?.isRestrictedExplosivesPrecursor &&
      !error?.details?.certificate.isValid,
  )
}
