import { AttributedPrevisionnel } from '@b2ag/previsionnel'
import { ProductHit, SearchResult } from '@b2ag/search'
import type { StorageKey } from '@b2ag/utils/src/localStorage'

export const PREVISIONNEL_LOCAL_STORAGE_KEY: StorageKey<AttributedPrevisionnel> = 'previsionnel'
export const PREVISIONNEL_CURRENT_PRODUCT_LOCAL_STORAGE_KEY: StorageKey<AttributedPrevisionnel> =
  'previsionnel-current-product'

export interface PrevisionnelSearchResult extends SearchResult {
  $el: HTMLElement | null
  onIconClearClick()
  preselectOption(index: number)
}

export interface PrevisionnelProductHit extends ProductHit {
  _highlightResult: { name: { value: string } }
  type: string
}

export interface PrevisionnelSearchSettings {
  hitsPerPage: number
  filters: string
}
