import { inject, provide, type Ref, ref } from 'vue'
import type { Store } from 'vuex'
import { getMembershipsCountsUsecase } from '../dependency-injection'

export interface MembershipCountStore {
  countTotal: Ref<number>
  countRegistered: Ref<number>
  countUnregistered: Ref<number>
  loadMembershipsCounts: () => Promise<void>
}

const MEMBERSHIP_COUNT_SYMBOL = Symbol('membershipCountSymbol')

export default function createMembershipCountStore(store: Store<any>): MembershipCountStore {
  const countTotal = ref(0)
  const countUnregistered = ref(0)
  const countRegistered = ref(0)

  const loadMembershipsCounts = async () => {
    const membershipsCounts = await getMembershipsCountsUsecase(
      store.getters.currentCooperative.id,
      store.getters.user.technical_sale_information.uuid,
    )
    countTotal.value = membershipsCounts.countTotal
    countUnregistered.value = membershipsCounts.countUnregistered
    countRegistered.value = membershipsCounts.countRegistered
  }

  return {
    countTotal,
    countRegistered,
    countUnregistered,
    loadMembershipsCounts,
  }
}

export function provideMembershipCounts(store) {
  provide<MembershipCountStore>(MEMBERSHIP_COUNT_SYMBOL, createMembershipCountStore(store))
}

export function useMembershipCounts(): MembershipCountStore {
  const store = inject<MembershipCountStore>(MEMBERSHIP_COUNT_SYMBOL)
  if (!store) {
    throw new Error('Membership store was not initialized.')
  }
  return store
}
