import type { MembershipsCounts } from '../domain/membership.interface'
import type { MembershipRepository } from '../infra/membership.repository'

interface Usecase {
  (partnerId: number, technicianUid: string): Promise<MembershipsCounts>
}

export function getMembershipsCounts(membershipRepository: MembershipRepository): Usecase {
  return async (partnerId, technicianUid) => {
    // eslint-disable-next-line no-return-await
    return await membershipRepository.fetchMembershipsCounts(partnerId, technicianUid)
  }
}
