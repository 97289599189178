/* eslint-disable import/prefer-default-export */

// This is probably not supported in our Kube's version of node.js but it's OK
// because the rules for en-US and fr-FR are similar enough to pass the unit tests
// (just don't test with fractions between 1 and 2).
// If we do write tests that fail because of that, we'll need to install the full ICU for testing.
// Actual web browsers on a modern OS should never have that problem.
const plural = new Intl.PluralRules('fr-FR')

/** @param {{one?: string, other: string}} definition the French CLDR number categories are `one` and `other`
 * @param {number} n
 * @returns {string} */
export function number(definition, n) {
  const category = plural.select(n)
  return definition[category] || definition.other
}
