import type { AladinError } from '@aladin/shared-error'
import { ShoppingListApiErrorType } from '@aladin/shared-shopping-list-sdk'
import type { ShoppingList } from './shopping-list.model'

export interface ShoppingListOutdatedError extends AladinError {
  details: {
    updatedShoppingList: ShoppingList
  }
}

export function isShoppingListOutdatedError(error: AladinError): error is ShoppingListOutdatedError {
  return error.key === ShoppingListApiErrorType.OUTDATED_SHOPPING_LIST_ERROR && !!error?.details?.updatedShoppingList
}
