import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import type { AxiosInstance } from '@b2ag/axios'
import snakeCase from 'lodash/snakeCase'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import type { Membership } from './membership.utils'

const isValueNotEmpty = ([, value]) =>
  (Array.isArray(value) && value.length > 0) ||
  (typeof value === 'string' && value.trim().length > 0) ||
  typeof value === 'number' ||
  typeof value === 'boolean'

export interface MembershipFilter {
  registered?: boolean
  search?: string
}

interface MembershipsParams {
  limit?: number
  page?: number
  filter?: MembershipFilter
  search?: string
  membershipsNumbers?: string
}

function membershipsFilterToQueryParam(params: MembershipsParams = {}): string {
  return new URLSearchParams(
    Object.entries(params)
      .filter(isValueNotEmpty)
      .map(([key, value]) => [snakeCase(key), value]),
  ).toString()
}

export const MEMBERSHIPS_LIST_LIMIT = 10
const FIRST_PAGE = 1

export class MembershipService {
  public api: AxiosInstance

  constructor({ userApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1`, { callerId: buildCallerId() })
  }

  async list(
    partnerId: number,
    technicianUid: string,
    page = FIRST_PAGE,
    filter?: MembershipFilter,
    search?: string,
    membershipsNumbers?: string,
    limit = MEMBERSHIPS_LIST_LIMIT,
  ) {
    const queryParam = membershipsFilterToQueryParam({ limit, page, ...filter, search, membershipsNumbers })
    const response = await this.api.get<Membership[]>(
      `/partners/${partnerId}/technicians/${technicianUid}/memberships?${queryParam}`,
    )
    response.data.forEach((membership) => {
      const fullName = `${membership.first_name} ${membership.last_name}`
      Object.assign(membership, {
        full_name: fullName,
      })
    })
    const contentRange = response.headers['content-range']
    const totalCount = (contentRange && parseInt(contentRange.split('/')[1], 10)) || response.data.length
    return { memberships: response.data, totalCount }
  }

  async get(partnerId: number, membershipNumber: string) {
    const response = await this.api.get(`/partners/${partnerId}/memberships/${membershipNumber}`)
    const membership = response.data
    const fullName = `${membership.first_name} ${membership.last_name}`
    return {
      ...membership,
      full_name: fullName,
    }
  }
}
