// eslint-disable-next-line import/prefer-default-export
export const isAccountRegistered = (membership) =>
  membership.profiles ? membership.profiles && membership.profiles.some((p) => p.confirmed_at) : false

export const statusType = Object.freeze({
  ALL: 'ALL',
  REGISTERED: 'REGISTERED',
  UNREGISTERED: 'UNREGISTERED',
})

export interface Profile {
  confirmed_at: string | null
  created_from: string
}

export interface TechnicalSale {
  uuid: string
}

export interface MembershipWarnings {
  paymentMethods: boolean
  shippingAddresses: boolean
  solvency: boolean
  emailAddress: boolean
  phoneNumber: boolean
}

export interface Membership {
  card_payment: boolean
  certiphyto_end_date: string
  company_name: string
  coop_account_maximum_allowed_amount: number
  coop_account_payment: boolean
  email: string
  first_name: string
  full_name: string
  full_name_with_company: string
  id: number
  last_name: string
  membership_number: string
  phone_number: string
  profiles: Profile[]
  confirmed_at: null
  created_from: string
  region: string
  sepa_payment: true
  shipping_addresses: object[]
  siret_number: string
  solvency: boolean
  technical_sales: TechnicalSale[]
  valid_certiphyto: boolean
  registered?: boolean
  warnings: MembershipWarnings
}

export type MembershipApi = Membership
