import { useLogger } from '@b2ag/composables'
import { createShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { useAuthService } from '@b2ag/auth'
import { MembershipService } from '@b2ag/membership/src/services/membership.service'
import { getConfig } from '@b2ag/micro-front-tools'
import { createShoppingListRepository } from './infra/shopping-list.repository'
import { createCheckShoppingListMembershipUseCase } from './usecases/check-shopping-list-membership.usecase'
import { createDuplicateShoppingListUseCase } from './usecases/duplicate-shopping-list.usecase'
import { createOwnedFetchShoppingListUseCase } from './usecases/fetch-owned-shopping-list.usecase'
import { createFlushShoppingListValidationErrorsUseCase } from './usecases/flush-shopping-list-validation-errors.usecase'
import { createOrderShoppingListUseCase } from './usecases/order-shopping-list.usecase'
import { createRemoveLineFromShoppingListUsecase } from './usecases/remove-line-from-shopping-list'
import { createRemoveShoppingListUseCase } from './usecases/remove-shopping-list.usecase'
import { createSaveShoppingListUseCase } from './usecases/save-shopping-list.usecase'
import { createSendShoppingListUseCase } from './usecases/send-shopping-list.usecase'

export const logger = useLogger('shopping-list')

const userApiHost = getConfig('USER_API_HOST')
const membershipService = new MembershipService({ userApiHost })

const shoppingListRepository = createShoppingListRepository({
  shoppingListDataSource: createShoppingListApiClient({
    shoppingListApiHost: getConfig('SHOPPING_LIST_API_HOST'),
    useCartV2: true,
    accessTokenSource: () => useAuthService().getAccessToken(),
  }),
  membershipService,
})

const fetchOwnedShoppingListUseCase = createOwnedFetchShoppingListUseCase({
  shoppingListRepository,
})

const saveShoppingListUseCase = createSaveShoppingListUseCase({ shoppingListRepository })

const removeLineFromShoppingListUseCase = createRemoveLineFromShoppingListUsecase({
  shoppingListRepository,
})

const sendShoppingListUseCase = createSendShoppingListUseCase({ shoppingListRepository })

const orderShoppingListUseCase = createOrderShoppingListUseCase({ shoppingListRepository })

const flushShoppingListValidationErrorsUseCase = createFlushShoppingListValidationErrorsUseCase({
  shoppingListRepository,
})

const removeShoppingListUseCase = createRemoveShoppingListUseCase({
  shoppingListRepository,
})

const duplicateShoppingListUseCase = createDuplicateShoppingListUseCase({ shoppingListRepository })
const checkShoppingListMembershipUseCase = createCheckShoppingListMembershipUseCase({ shoppingListRepository })

export {
  checkShoppingListMembershipUseCase,
  duplicateShoppingListUseCase,
  fetchOwnedShoppingListUseCase,
  flushShoppingListValidationErrorsUseCase,
  orderShoppingListUseCase,
  removeLineFromShoppingListUseCase,
  removeShoppingListUseCase,
  saveShoppingListUseCase,
  sendShoppingListUseCase,
}
