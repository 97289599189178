import type { CreatedTargetShoppingList } from '../domain/target-membership.interface'
import type { TargetMembershipRepository } from '../infra/target-membership.repository'

export interface CreateTargetShoppingListUsecase {
  execute: (partnerId: number, membershipNumber: string) => Promise<CreatedTargetShoppingList>
}

interface Dependencies {
  targetMembershipRepository: TargetMembershipRepository
}

export function createCreateTargetShoppingListUsecase(deps: Dependencies): CreateTargetShoppingListUsecase {
  async function execute(partnerId, membershipNumber) {
    return await deps.targetMembershipRepository.createNewTargetShoppingList(partnerId, membershipNumber)
  }

  return { execute }
}
