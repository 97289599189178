import { v4 as uuidv4 } from 'uuid'
import { i18n } from '@b2ag/locale'
import type { TechnicianDashboard } from '@b2ag/membership'
import type { DashboardService } from '@b2ag/membership/src/services/dashboard.service'
import type { MembershipService as MembershipDataSource } from '@b2ag/membership/src/services/membership.service'
import type { ShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { ShoppingListStatusApi } from '@aladin/shared-shopping-list-sdk'
import type {
  Filter,
  Membership,
  MembershipsCounts,
  Paginated,
  Pagination,
  ShoppingListInfo,
} from '../domain/membership.interface'
import { getMembershipShoppingListItem, shoppingListApiToShoppingListInfo } from './membership.mappers'

interface Dependencies {
  membershipDataSource: MembershipDataSource
  shoppingListDataSource: ShoppingListApiClient
  dashboardDatasource: DashboardService
}

export interface MembershipRepository {
  fetchMembershipWithShoppingLists: (
    partnerId: number,
    technicianUid: string,
    filter: Filter,
    pagination: Pagination,
  ) => Promise<Paginated<Membership>>
  fetchMembershipsCounts: (partnerId: number, technicianUid: string) => Promise<MembershipsCounts>
  createShoppingList: (partnerId: number, membership: Membership) => Promise<ShoppingListInfo>
}

export function createMembershipRepository(deps: Dependencies): MembershipRepository {
  const { membershipDataSource, shoppingListDataSource, dashboardDatasource } = deps

  async function fetchMembershipWithShoppingLists(
    partnerId: number,
    technicianUid: string,
    filter: Filter,
    pagination: Pagination,
    membershipsNumbers?: string,
  ): Promise<Paginated<Membership>> {
    const membershipData = await membershipDataSource.list(
      partnerId,
      technicianUid,
      pagination.page,
      {
        registered: filter.registered,
      },
      filter.search,
      membershipsNumbers,
      pagination.limit,
    )
    const membershipNumbersList = membershipData.memberships.map((membership) => membership.membership_number)
    const shoppingListData = await shoppingListDataSource.fetchOwnedShoppingLists({
      membershipNumbers: membershipNumbersList,
      status: ShoppingListStatusApi.pending,
    })
    return {
      items: getMembershipShoppingListItem(membershipData.memberships, shoppingListData),
      meta: {
        totalCount: membershipData.totalCount,
      },
    }
  }

  async function fetchMembershipsCounts(partnerId: number, technicianUid: string): Promise<MembershipsCounts> {
    const technicianDashboard: TechnicianDashboard = await dashboardDatasource.getTechnicianDashboard(
      partnerId,
      technicianUid,
    )
    return {
      countRegistered: technicianDashboard.registered_memberships_count,
      countUnregistered: technicianDashboard.unregistered_memberships_count,
      countTotal: technicianDashboard.total_memberships_count,
    }
  }

  async function createShoppingList(partnerId: number, membership: Membership): Promise<ShoppingListInfo> {
    const shoppingListApi = await shoppingListDataSource.saveShoppingList({
      title: `${i18n._('Liste')} ${uuidv4().substring(0, 6).toUpperCase()}`,
      partner_id: partnerId,
      recipient: {
        company_name: membership.companyName,
        first_name: membership.firstName,
        last_name: membership.lastName,
        membership_number: membership.membershipNumber,
        phone_number: membership.phoneNumber,
        email: membership.email,
        isRegistered: membership.registered,
      },
      lines: [],
      comment: '',
    })
    return shoppingListApiToShoppingListInfo(shoppingListApi)
  }

  return {
    fetchMembershipWithShoppingLists,
    fetchMembershipsCounts,
    createShoppingList,
  }
}
