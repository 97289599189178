import type { CurrentTargetShoppingList } from '../domain/target-membership.interface'
import type { TargetMembershipRepository } from '../infra/target-membership.repository'

export interface FetchTargetShoppingListUsecase {
  execute: (membershipNumber: string, shoppingListId?: string) => Promise<CurrentTargetShoppingList | null>
}

interface Dependencies {
  targetMembershipRepository: TargetMembershipRepository
}

export function createFetchTargetShoppingListUsecase(deps: Dependencies): FetchTargetShoppingListUsecase {
  async function execute(membershipNumber, shoppingListId?: string) {
    return await deps.targetMembershipRepository.fetchTargetShoppingList(membershipNumber, shoppingListId)
  }

  return { execute }
}
