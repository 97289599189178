import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

interface CheckShoppingListMembershipUseCase {
  (params: { partnerId: number; membershipNumber: string }): Promise<boolean>
}

export function createCheckShoppingListMembershipUseCase({
  shoppingListRepository,
}: Dependencies): CheckShoppingListMembershipUseCase {
  return async (params: { partnerId: number; membershipNumber: string }): Promise<boolean> => {
    return await shoppingListRepository.checkShoppingListMembership(params)
  }
}
