import Vue from 'vue'
import { membershipService } from '@/services'

export default {
  namespaced: true,
  state: {
    currentPageMembershipsList: [],
    membershipsTotalCount: 0,
    currentMembership: undefined,
  },
  getters: {
    getCurrentPageMembershipsList(state) {
      return state.currentPageMembershipsList
    },
    getCurrentMembership(state) {
      return state.currentMembership
    },
    membershipsTotalCount(state) {
      return state.membershipsTotalCount
    },
  },
  mutations: {
    SET_CURRENT_PAGE_MEMBERSHIPS(state, memberships) {
      Vue.set(
        state,
        'currentPageMembershipsList',
        memberships.reduce(
          (acc, newMember) => (acc.find((current) => current.id === newMember.id) ? acc : acc.concat(newMember)),
          state.currentPageMembershipsList,
        ),
      )
    },
    SET_MEMBERSHIPS_TOTAL_COUNT(state, membershipsTotalCount) {
      Vue.set(state, 'membershipsTotalCount', membershipsTotalCount)
    },
    SET_CURRENT_MEMBERSHIP(state, currentMembership) {
      state.currentMembership = currentMembership
    },
    SET_RESET_PAGE_MEMBERSHIPS(state) {
      Vue.set(state, 'currentPageMembershipsList', [])
    },
  },
  actions: {
    async fetchMemberships({ dispatch, commit }, { partnerId, technicianUid, page, filter, search, limit }) {
      try {
        if (!page || page === 1) {
          commit('SET_RESET_PAGE_MEMBERSHIPS')
        }

        const { memberships, totalCount } = await membershipService.list(
          partnerId,
          technicianUid,
          page || 1,
          filter,
          search,
          undefined,
          limit,
        )
        commit('SET_CURRENT_PAGE_MEMBERSHIPS', memberships)
        commit('SET_MEMBERSHIPS_TOTAL_COUNT', totalCount)
      } catch (error) {
        dispatch('errors/setMembershipsListError', { membershipsListError: error }, { root: true })
      }
    },
    async fetchMembershipByMembershipNumber({ commit, rootGetters }, { membershipNumber }) {
      const { currentCooperativeId } = rootGetters
      const membership = await membershipService.get(currentCooperativeId, membershipNumber)
      commit('SET_CURRENT_MEMBERSHIP', membership)
    },
  },
}
