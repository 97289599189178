
import { defineComponent } from 'vue'

const __default__ = defineComponent({
  name: 'AdnTag',
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent',
    },
    borderColor: {
      type: String,
      default: 'black',
    },
    borderStyle: {
      type: String,
      default: 'solid',
    },
    textColor: {
      type: String,
      default: 'black',
    },
    shape: {
      type: String,
      default: '3rem',
    },
    fontSize: {
      type: String,
      default: 'var(--font-size-s)',
    },
    fontWeight: {
      type: String,
      default: 'normal',
    },
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "5e5552a8": (_vm.borderStyle),
  "601d2f44": (_vm.borderColor),
  "6f43d2a8": (_vm.shape),
  "6df26600": (_vm.fontWeight),
  "47dc6c89": (_vm.fontSize),
  "5e7a5486": (_vm.textColor),
  "79af9d1c": (_vm.backgroundColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__