import type { CurrentShoppingListWithMembership } from '../domain/target-membership.interface'
import type { TargetMembershipRepository } from '../infra/target-membership.repository'

export interface ChangeTargetMembershipUsecase {
  execute: (partnerId: number, membershipNumber: string) => Promise<CurrentShoppingListWithMembership>
}

interface Dependencies {
  targetMembershipRepository: TargetMembershipRepository
}

export function createChangeTargetMembershipUsecase(deps: Dependencies): ChangeTargetMembershipUsecase {
  async function execute(partnerId, membershipNumber) {
    const targetShoppingList = await deps.targetMembershipRepository.fetchTargetShoppingList(membershipNumber)
    const targetMembership = await deps.targetMembershipRepository.fetchTargetMembership(partnerId, membershipNumber)
    return {
      membership: targetMembership,
      shoppingList: targetShoppingList?.shoppingList || null,
      shoppingListToList: targetShoppingList?.shoppingListToList || null,
    }
  }

  return { execute }
}
