// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore?tab=readme-ov-file#_invert

/* eslint-disable no-param-reassign, prefer-destructuring */

const invert = (object: any) =>
  Object.entries(object).reduce((acc, current: any[]) => {
    acc[current[1]] = current[0]
    return acc
  }, {})

export default invert
