import type { MembershipApi } from '@b2ag/membership'
import type { MembershipService } from '@b2ag/membership/src/services/membership.service'
import {
  type ShoppingListApi,
  type ShoppingListApiClient,
  ShoppingListApiErrorType,
} from '@aladin/shared-shopping-list-sdk'

import type { TargetShoppingListToList } from '@b2ag/target-membership/src/domain/target-membership.interface'
import { mapShoppingListToList } from '@b2ag/target-membership/src/infra/target-membership.mappers'
import type { ShoppingList } from '../domain/shopping-list.model'
import {
  modelToDuplicateShoppingListPayloadApi,
  modelToSaveShoppingListPayloadApi,
  shoppingListApiToModel,
} from './shopping-list.mappers'

interface Dependencies {
  shoppingListDataSource: ShoppingListApiClient
  membershipService: MembershipService
}

export interface ShoppingListRepository {
  fetchShoppingList(id: string): Promise<ShoppingList | null>
  saveShoppingList(shoppingList: ShoppingList): Promise<ShoppingList>
  sendShoppingList(shoppingListId: string): Promise<ShoppingList>
  orderShoppingList(shoppingListId: string): Promise<ShoppingList>
  flushShoppingListValidationErrors(shoppingListId: string): Promise<ShoppingList | null>
  removeShoppingList(shoppingListId: string): Promise<void>
  duplicateShoppingList(params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }>
  checkShoppingListMembership(params: { partnerId: number; membershipNumber: string }): Promise<boolean>
}

export function createShoppingListRepository({
  shoppingListDataSource,
  membershipService,
}: Dependencies): ShoppingListRepository {
  async function fetchShoppingList(id: string): Promise<ShoppingList | null> {
    const shoppingListApi = await shoppingListDataSource.fetchOwnedShoppingList(id)
    if (shoppingListApi === null) {
      return null
    }
    return shoppingListApiToModel(shoppingListApi)
  }

  async function saveShoppingList(shoppingList: ShoppingList): Promise<ShoppingList> {
    const payload = modelToSaveShoppingListPayloadApi(shoppingList)
    const shoppingListApiSaved = await shoppingListDataSource.saveShoppingList(payload)
    return shoppingListApiToModel(shoppingListApiSaved)
  }

  async function sendShoppingList(shoppingListId: string): Promise<ShoppingList> {
    let shoppingListApi: ShoppingListApi
    try {
      shoppingListApi = await shoppingListDataSource.sendShoppingList(shoppingListId)
      return shoppingListApiToModel(shoppingListApi)
    } catch (error: any) {
      if (
        error.key === ShoppingListApiErrorType.OUTDATED_SHOPPING_LIST_ERROR &&
        error?.details?.updated_shopping_list
      ) {
        const { updated_shopping_list: updatedShoppingListApi } = error.details
        const sendError = {
          key: error.key,
          message: error.message,
          details: {
            updatedShoppingList: shoppingListApiToModel(updatedShoppingListApi),
          },
        }
        throw sendError
      }
      throw error
    }
  }

  async function orderShoppingList(shoppingListId: string): Promise<ShoppingList> {
    try {
      return shoppingListApiToModel(await shoppingListDataSource.checkoutV2ShoppingList(shoppingListId))
    } catch (error: any) {
      if (
        error.key === ShoppingListApiErrorType.OUTDATED_SHOPPING_LIST_ERROR &&
        error?.details?.updated_shopping_list
      ) {
        const { updated_shopping_list: updatedShoppingListApi } = error.details
        const sendError = {
          key: error.key,
          message: error.message,
          details: {
            updatedShoppingList: shoppingListApiToModel(updatedShoppingListApi),
          },
        }
        throw sendError
      }
      throw error
    }
  }

  async function flushShoppingListValidationErrors(shoppingListId: string): Promise<ShoppingList | null> {
    const shoppingListApi = await shoppingListDataSource.flushShoppingListValidationErrors(shoppingListId)
    if (shoppingListApi === null) {
      return null
    }
    return shoppingListApiToModel(shoppingListApi)
  }

  async function removeShoppingList(shoppingListId: string): Promise<void> {
    await shoppingListDataSource.removeShoppingList(shoppingListId)
  }

  async function duplicateShoppingList(params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }> {
    const payload = modelToDuplicateShoppingListPayloadApi(params)
    const shoppingListApiDuplicated = await shoppingListDataSource.duplicateShoppingList(params.shoppingListId, payload)
    return {
      shoppingList: shoppingListApiToModel(shoppingListApiDuplicated),
      shoppingListToList: mapShoppingListToList(shoppingListApiDuplicated),
    }
  }
  async function checkShoppingListMembership(params: {
    partnerId: number
    membershipNumber: string
  }): Promise<boolean> {
    try {
      const result = await membershipService.get(params.partnerId, params.membershipNumber)
      if (!result) {
        return true
      }
      return false
    } catch (error: any) {
      if (error.message.includes('403')) {
        return true
      }
      return false
    }
  }

  return {
    fetchShoppingList,
    saveShoppingList,
    sendShoppingList,
    orderShoppingList,
    flushShoppingListValidationErrors,
    removeShoppingList,
    duplicateShoppingList,
    checkShoppingListMembership,
  }
}
