import type { MembershipFilter } from '@b2ag/membership'
import { MEMBERSHIPS_LIST_LIMIT } from '@b2ag/membership'
import type { Store } from '@b2ag/store/src/interfaces/store.interface'
import { computed, type ComputedRef, inject, provide, reactive, ref, type Ref } from 'vue'
import { getMembershipsWithShoppingListsUseCase, createShoppingListUseCase } from '../dependency-injection'
import type { Membership, ShoppingListInfo } from '../domain/membership.interface'

interface PaginatedState<T> {
  items: T[]
  totalCount: number
  maxPages: number
  currentPage: number
}

interface MembershipState extends PaginatedState<Membership> {
  filter: MembershipFilter
}

export interface MembershipStore {
  state: MembershipState
  isStatusFilterAll: ComputedRef<boolean>
  isStatusFilterRegistered: ComputedRef<boolean>
  isStatusFilterUnregistered: ComputedRef<boolean>
  isMembershipsListEmpty: ComputedRef<boolean>
  fetchMembershipListError: Ref<Error | null>
  loading: Ref<boolean>
  reset: () => void
  reloadPage: () => Promise<void>
  fetchPage: (page: number) => Promise<void>
  createShoppingList: (membership: Membership) => Promise<ShoppingListInfo>
  setRegistered: (membershipFilter: MembershipFilter, page: number) => Promise<void>
}

const MEMBERSHIP_SYMBOL = Symbol('membershipSymbol')

export function createMembershipStore(store: Store): MembershipStore {
  const state = reactive<MembershipState>({
    items: [],
    totalCount: 0,
    maxPages: 1,
    currentPage: 1,
    filter: { search: '', registered: undefined },
  })

  const loading = ref<boolean>(true)
  const fetchMembershipListError = ref<Error | null>(null)

  async function reloadPage() {
    try {
      loading.value = true
      const data = await getMembershipsWithShoppingListsUseCase(
        store.getters.currentCooperative.id,
        store.getters.user.technical_sale_information.uuid,
        state.filter,
        { page: state.currentPage, limit: MEMBERSHIPS_LIST_LIMIT }, // TODO limit to be redefined
      )
      state.items = data.items
      state.totalCount = data.meta.totalCount
      state.maxPages = Math.ceil(state.totalCount / MEMBERSHIPS_LIST_LIMIT) // TODO to ajust when redefine limit
    } catch (error: any) {
      fetchMembershipListError.value = error
    } finally {
      loading.value = false
    }
  }

  async function fetchPage(page: number) {
    state.currentPage = page
    await reloadPage()
  }

  async function createShoppingList(membership: Membership): Promise<ShoppingListInfo> {
    try {
      loading.value = true
      return await createShoppingListUseCase(store.getters.currentCooperative.id, membership)
    } finally {
      loading.value = false
    }
  }

  async function setRegistered(membershipFilter: MembershipFilter, page: number) {
    state.currentPage = page
    state.filter.registered = membershipFilter.registered
    await reloadPage()
  }

  async function reset() {
    state.items = []
    state.totalCount = 0
    state.maxPages = 1
    state.currentPage = 1
    state.filter.search = ''
    state.filter.registered = undefined
  }

  return {
    state,
    isStatusFilterAll: computed(() => state.filter.registered === undefined),
    isStatusFilterRegistered: computed(() => state.filter.registered === true),
    isStatusFilterUnregistered: computed(() => state.filter.registered === false),
    isMembershipsListEmpty: computed(() => !!state.items.length),
    fetchMembershipListError,
    loading,
    reloadPage,
    fetchPage,
    setRegistered,
    createShoppingList,
    reset,
  }
}

export function provideMembershipStore(store) {
  provide<MembershipStore>(MEMBERSHIP_SYMBOL, createMembershipStore(store))
}

export function useMembershipStore(): MembershipStore {
  const store = inject<MembershipStore>(MEMBERSHIP_SYMBOL)
  if (!store) {
    throw new Error('Membership store was not initialized.')
  }
  return store
}
