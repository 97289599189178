import { ShopStoreActions } from '@b2ag/store/src/interfaces/shop.interface'
import {
  initialShopState,
  shopMutations,
  shopGetters,
  shopActions as globalShopActions,
  type ShopState,
  type ActionImplementation,
} from '@b2ag/store/src/shop.store'
import { offerService, productService } from '@/services'
import {
  commonFetchProducts,
  simpleFetchProducts,
  extractCoopAndMembership,
  getMissingProductsIds,
  basicFetchProducts,
} from './utils'

export type { ShopState }

const shopActions: { [K in keyof ShopStoreActions]: ActionImplementation<K> } = {
  ...globalShopActions,
  async fetchMissingProducts({ getters, commit, rootGetters }, productIdList: string[]) {
    const alreadyLoadedProductIds = getters.getAlgoliaProductsByIds(productIdList).map(({ _id: id }) => id)
    const missingProductsIds = getMissingProductsIds(
      productIdList,
      alreadyLoadedProductIds,
      getters.getProductsForPrice,
    )
    if (missingProductsIds.length) {
      const { currentCooperativeId, targetCustomerMembership } = rootGetters
      await simpleFetchProducts(commit, currentCooperativeId, targetCustomerMembership, missingProductsIds)
    }
  },

  // SPECIFIC TECH ACTIONS

  fetchProduct(context, { productId }) {
    const { currentCooperativeId, targetCustomerMembership } = extractCoopAndMembership(context.rootGetters)
    const cityCode = context.rootGetters['addresses/currentCityCode']
    const productsAndOffers = Promise.all([
      productService.getCatalog(productId, currentCooperativeId).then((product) => [product]),
      offerService.findOffers([productId], currentCooperativeId, targetCustomerMembership, { cityCode }),
    ])
    return commonFetchProducts(context, currentCooperativeId, productsAndOffers)
  },
  async fetchProducts(context, { variants }) {
    const variantIds = variants.map((variant) => variant.id)
    const productIds = Array.from(new Set(variants.map((variant) => variant.product?._id)))
    if (!productIds.length || !variantIds.length) return
    const cityCode = context.rootGetters['addresses/currentCityCode']

    const { currentCooperativeId, targetCustomerMembership } = extractCoopAndMembership(context.rootGetters)
    const productsAndOffers = Promise.all([
      productService.findProducts(productIds, currentCooperativeId),
      offerService.findOffersByVariants(variantIds, currentCooperativeId, targetCustomerMembership, { cityCode }),
    ])
    // eslint is too dumb to see that Promise<void> is consistent with void in an async function
    // eslint-disable-next-line consistent-return
    return commonFetchProducts(context, currentCooperativeId, productsAndOffers)
  },
  async fetchExclusiveProductThumbnail({ getters, rootGetters, commit }, productId: string) {
    const { currentCooperativeId } = rootGetters
    const product = getters.getProductById(productId)
    if (!product) {
      const productsWithThumbnails = await basicFetchProducts([productId], currentCooperativeId)
      commit('SET_PRODUCT_MAP', { productList: productsWithThumbnails, forPrice: true })
    }
  },
}

const shop = {
  namespaced: true,
  state: () => initialShopState,
  mutations: shopMutations,
  actions: shopActions,
  getters: shopGetters,
}
export default shop
