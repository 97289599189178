import type { CurrentShoppingListWithMembership } from '../domain/target-membership.interface'
import type { TargetMembershipRepository } from '../infra/target-membership.repository'

export interface FetchDefaultTargetMembershipUsecase {
  execute: (partnerId: number, technicianUid: string) => Promise<CurrentShoppingListWithMembership | null>
}

interface Dependencies {
  targetMembershipRepository: TargetMembershipRepository
}

export function createFetchDefaultTargetMembershipUsecase(deps: Dependencies): FetchDefaultTargetMembershipUsecase {
  async function execute(partnerId, technicianUid): Promise<CurrentShoppingListWithMembership | null> {
    return await deps.targetMembershipRepository.fetchDefaultTargetMembership({ partnerId, technicianUid })
  }

  return { execute }
}
