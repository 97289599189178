import type { ShoppingList } from '../domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

interface SaveShoppingListUseCase {
  (shoppingList: ShoppingList): Promise<ShoppingList>
}

export function createSaveShoppingListUseCase({ shoppingListRepository }: Dependencies): SaveShoppingListUseCase {
  return async (shoppingList: ShoppingList): Promise<ShoppingList> => {
    return await shoppingListRepository.saveShoppingList(shoppingList)
  }
}
