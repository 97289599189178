import type { AladinError } from '@aladin/shared-error'

export interface ShoppingList {
  id: string
  partnerId: number
  title: string
  status: ShoppingListStatus
  recipient: ShoppingListRecipient
  updatedAt: Date
  lines: ShoppingListItem[]
  comment: string
  totalDetails: ShoppingListTotalDetails
  checkoutId?: number
  checkoutOriginType?: CheckoutOriginType
  errors: AladinError<Record<string, unknown>>[]
}

export interface ShoppingListTotalDetails {
  articleCount: number
  productCount: number
  rpd?: number
  totalWithoutTaxes: string
  totalWithoutVat: string
  totalWithTaxes: string
  tvaDetails: string
  rpdDetails?: string
  discounts?: string
  dueDates?: {
    total: string
    label: string
  }[]
  estimatedShipping?: string
}

export interface ShoppingListRecipient {
  firstName: string
  lastName: string
  fullName: string
  companyName: string
  membershipNumber: string
  isRegistered?: boolean
  phoneNumber: string | null
  email: string
}

export enum ShoppingListStatus {
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  PURCHASED = 'PURCHASED',
  SENT = 'SENT',
  UNKNOWN = 'UNKNOWN',
  CHECKOUT = 'CHECKOUT',
}

export enum ShoppingListStatusExtension {
  CHECKOUT_BY_TC = 'CHECKOUT_BY_TC',
}

export enum CheckoutOriginType {
  SHOPPING_LIST_TYPE = 'shopping_list',
  PURCHASE_ORDER_TYPE = 'purchase_order',
}

export interface ShoppingListFamily {
  name: string
  items: ShoppingListItem[]
}

export interface ShoppingListItem {
  productId: string
  quotas?: {
    quantityMax?: number
  }
  variant: ShoppingListItemVariant
  offer: ShoppingListItemOffer
  quantity: number
  totalPriceWithoutTaxes: string
  totalPriceWithTaxes: string
  discount?: ItemDiscount
  taxes: ShoppingListItemTaxes
  shipping: ShoppingListItemShipping
  clickAndCollectStoreCode?: string
  stock?: number
  paymentDueDate?: ShoppingListPaymentDueDate
}

export interface ShoppingListItemVariant {
  name: string
  quantityPerUnit?: number
  unit?: string
  variantIsSoldAsUnit: boolean
  packaging?: string
  family: string
}

export interface ShoppingListItemOffer {
  id: string
  description?: string
  unitPrice: string
  priceType: string
  deliveryDate?: Date
  quantityMax?: number
  quantityMin: number
  quantityStep: number
  measurementPrice?: string
}

interface ItemDiscount {
  value?: number
  unit: string
  measureUnit: string
  discountedUnitPrice: string
  discountedMeasurementPrice?: string
}

interface ShoppingListItemTaxes {
  rpdUnitPrice?: string
  tvaUnitPrice: string
  tvaPercent?: number
}

interface ShoppingListItemShipping {
  logisticOffers: LogisticOfferForSale[]
  shippingMethods: string[]
}

export interface LogisticOfferForSale {
  name: string
  deliveryModeName: string
}

export interface ShoppingListPaymentDueDate {
  reference: string
  title: string
  description: string
  value: string
}

export interface ShoppingListRef {
  id: string
}

export interface LineRef {
  offer: {
    id: string
  }
  quantity: number
  paymentDueDate?: {
    reference: string
  }
  clickAndCollect?: {
    storeCode: string
  }
}
