import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import type { AxiosInstance } from '@b2ag/axios'
import { InvertedVariantHit, Previsionnel } from '@b2ag/previsionnel'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

export default class PrevisionnelService {
  private readonly api: AxiosInstance

  constructor({ productApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1/previsionnels/`, { callerId: buildCallerId() })
  }

  async getPrevisionnel(previsionnelId: string): Promise<Previsionnel> {
    const { data } = await this.api.get(previsionnelId)
    return data
  }

  async getVariantsFromPrevisionnel(previsionnelId: string): Promise<InvertedVariantHit[]> {
    const { data } = await this.api.get(`${previsionnelId}/commercial-products`)
    return data
  }

  async savePrevisionnelShoppingListId(shoppingListId: string): Promise<void> {
    await this.api.post('shopping-list-id', shoppingListId, {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'text/plain',
      },
    })
  }
}
