export interface Pagination {
  page: number
  limit: number
}

export interface Membership {
  id: number
  firstName: string
  lastName: string
  companyName: string
  registered: boolean
  membershipNumber: string
  validCertiphyto: boolean
  shoppingListsInfo: ShoppingListInfo[] | null
  phoneNumber: string | null
  email: string
  warnings: MembershipWarnings
}

export interface ShoppingListInfo {
  id: string
  title: string
  totalWithoutTaxes: string
  items: number
  updatedAt?: Date
  status: ShoppingListInfoStatus
  families: ShoppingListInfoFamily[]
  checkoutOriginType?: CheckoutOriginType
  recipient?: ShoppingListInfoRecipient
}

export interface PaginatedShoppingListInfo {
  results: ShoppingListInfo[]
  recipient: ShoppingListInfoRecipient
  totalCount: number
  totalPages: number
  countsByStatus: CountByStatus
}

export interface ShoppingListInfoFamily {
  name: string
  countItems: number
}

export interface ShoppingListInfoRecipient {
  firstName: string
  lastName: string
  companyName: string
  membershipNumber: string
}

export type CountByStatus = Partial<Record<ShoppingListInfoStatus, number>>

export enum ShoppingListInfoStatus {
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  PURCHASED = 'PURCHASED',
  SENT = 'SENT',
  UNKNOWN = 'UNKNOWN',
  CHECKOUT = 'CHECKOUT',
}

export enum ShoppingListInfoStatusExtension {
  CHECKOUT_BY_TC = 'CHECKOUT_BY_TC',
}

export enum CheckoutOriginType {
  SHOPPING_LIST_TYPE = 'shopping_list',
  PURCHASE_ORDER_TYPE = 'purchase_order',
}

export interface Filter {
  registered?: boolean
  search?: string
}

export interface Paginated<T = unknown> {
  items: T[]
  meta: { totalCount: number }
}

export interface MembershipsCounts {
  countTotal: number
  countUnregistered: number
  countRegistered: number
}

export interface PaginationParameters {
  page: number
  limit: number
}

interface MembershipWarnings {
  paymentMethods: boolean
  shippingAddresses: boolean
  solvency: boolean
  emailAddress: boolean
  phoneNumber: boolean
}
