import type { ShoppingList } from '../domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface FetchShoppingListUseCase {
  (id: string): Promise<ShoppingList | null>
}

export function createOwnedFetchShoppingListUseCase({
  shoppingListRepository,
}: Dependencies): FetchShoppingListUseCase {
  return async (id) => {
    return await shoppingListRepository.fetchShoppingList(id)
  }
}

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}
