import type { FetchOwnedShoppingListsFilters, ShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import type {
  PaginatedShoppingListInfo,
  PaginationParameters,
  ShoppingListInfoStatus,
} from '../domain/membership.interface'
import {
  mapMetadataApiToCountsByStatus,
  mapRecipientApiToShoppingListInfoRecipient,
  mapShoppingListStatusToShoppingListStatusApi,
  shoppingListApiToShoppingListInfo,
} from './membership.mappers'

export interface ShoppingListRepository {
  fetchShoppingLists(
    membershipNumber: string,
    paginationParameters?: PaginationParameters,
    status?: ShoppingListInfoStatus,
  ): Promise<PaginatedShoppingListInfo | null>
}

export function createShoppingListRepository({ shoppingListDataSource }: Dependencies): ShoppingListRepository {
  function calculateTotalPages(totalCount: number, limit?: number) {
    return limit ? Math.ceil(totalCount / limit) : 1
  }

  async function fetchShoppingLists(
    membershipNumber: string,
    paginationParameters?: PaginationParameters,
    status?: ShoppingListInfoStatus,
  ): Promise<PaginatedShoppingListInfo | null> {
    const filters: FetchOwnedShoppingListsFilters = {}
    let membershipFilter: { membershipNumbers: string[] } | undefined

    if (membershipNumber.length > 0) {
      membershipFilter = { membershipNumbers: [membershipNumber] }
      filters.membershipNumbers = [membershipNumber]
    }
    if (status) {
      filters.status = mapShoppingListStatusToShoppingListStatusApi(status)
    }
    const [shoppingListsApi, metadata] = await Promise.all([
      shoppingListDataSource.fetchOwnedShoppingLists(filters, paginationParameters),
      shoppingListDataSource.fetchOwnedShoppingListsMetadata(membershipFilter),
    ])
    if (shoppingListsApi.length === 0) return null
    const [{ recipient }] = shoppingListsApi
    const totalCountFromStatus = filters.status ? metadata.count_by_status[filters.status] : metadata.total_count
    return {
      results: shoppingListsApi.map((shoppingListApi) => shoppingListApiToShoppingListInfo(shoppingListApi)),
      totalCount: metadata.total_count,
      recipient: mapRecipientApiToShoppingListInfoRecipient(recipient),
      totalPages: calculateTotalPages(totalCountFromStatus || metadata.total_count, paginationParameters?.limit),
      countsByStatus: mapMetadataApiToCountsByStatus(metadata),
    }
  }

  return {
    fetchShoppingLists,
  }
}

interface Dependencies {
  shoppingListDataSource: ShoppingListApiClient
}
