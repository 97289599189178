import { createShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { useAuthService } from '@b2ag/auth'
import { getConfig } from '@b2ag/micro-front-tools'
import { DashboardService } from './services/dashboard.service'
import { createMembershipRepository } from './infra/membership.repository'
import { createShoppingListRepository } from './infra/shopping-list.repository'
import { createCreateShoppingListForMembership } from './usecases/CreateShoppingListForMembership.usecase'
import { getMembershipsWithShoppingLists } from './usecases/GetMemberships.usecase'
import { getMembershipsCounts } from './usecases/GetMembershipsCounts.usecase'
import { createGetMembershipShoppingListsSummaryUsecase } from './usecases/get-membership-shopping-lists-summary.usecase'
import { createGetMembershipShoppingListsUsecase } from './usecases/get-membership-shopping-lists.usecase'
import { MembershipService } from './services/membership.service'

const membershipDataSource = new MembershipService({ userApiHost: getConfig('USER_API_HOST') })

const shoppingListDataSource = createShoppingListApiClient({
  shoppingListApiHost: getConfig('SHOPPING_LIST_API_HOST'),
  useCartV2: true,
  accessTokenSource: () => useAuthService().getAccessToken(),
})

const dashboardDatasource = new DashboardService({
  userApiHost: getConfig('USER_API_HOST'),
  reportingApiHost: getConfig('REPORTING_API_HOST'),
})

const membershipRepository = createMembershipRepository({
  membershipDataSource,
  shoppingListDataSource,
  dashboardDatasource,
})

const shoppingListRepository = createShoppingListRepository({
  shoppingListDataSource,
})

const getMembershipsWithShoppingListsUseCase = getMembershipsWithShoppingLists(membershipRepository)
const getMembershipsCountsUsecase = getMembershipsCounts(membershipRepository)

const createShoppingListUseCase = createCreateShoppingListForMembership(membershipRepository)

const getMembershipShoppingListsSummaryUsecase = createGetMembershipShoppingListsSummaryUsecase({
  shoppingListRepository,
})
const getMembershipShoppingListsUseCase = createGetMembershipShoppingListsUsecase({ shoppingListRepository })

export {
  createShoppingListUseCase,
  getMembershipShoppingListsSummaryUsecase,
  getMembershipShoppingListsUseCase,
  getMembershipsCountsUsecase,
  getMembershipsWithShoppingListsUseCase,
  // For legacy use only in front/tech/src/application/composables/useCurrentMembership.ts
  membershipDataSource,
}
