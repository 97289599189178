import { PrevisionnelStoreGetters } from '@b2ag/store/src/interfaces/previsionnel.interface'
import { PrevisionnelProductPayload, AttributedPrevisionnel, previsionnelHasVariants } from '@b2ag/previsionnel'

import { PREVISIONNEL_LOCAL_STORAGE_KEY } from '@/contexts/previsionnel/application/previsionnel'
import { getLocalStorageJSON } from '@b2ag/utils/src/localStorage'

const initialState = {
  productList: [] as PrevisionnelProductPayload[],
  previsionnel: { _id: '', userCode: '', thirdCode: '', farmCode: '', creationDate: '', cropYear: '' } as Omit<
    AttributedPrevisionnel,
    'productList'
  >,
}

export type PrevisionnelState = typeof initialState

const previsionnelMutations = {
  updateProductList(state: PrevisionnelState, productList?: AttributedPrevisionnel['productList']) {
    state.productList = productList ?? []
  },

  updatePrevisionnel(state: PrevisionnelState, previsionnel?: Omit<AttributedPrevisionnel, 'productList'>) {
    state.previsionnel = previsionnel ?? { ...initialState.previsionnel }
  },
}

type GetterImplementation<T extends keyof PrevisionnelStoreGetters> = (
  state: PrevisionnelState,
  getters: PrevisionnelStoreGetters,
) => PrevisionnelStoreGetters[T]

const previsionnelGetters: { [K in keyof PrevisionnelStoreGetters]: GetterImplementation<K> } = {
  previsionnel: (state) => state.previsionnel,
  productList: (state) => state.productList,

  numberOfProducts: (state) => state.productList.length,

  quantityByVariant: (state) => {
    if (!previsionnelHasVariants(state.productList)) return new Map()
    return new Map(
      state.productList.flatMap((product) =>
        product.variants.map((variant) => [variant.id, { quantity: product.quantity, unit: product.unit }]),
      ),
    )
  },

  isAssociated: (state) => state.previsionnel.associateShoppingListId !== undefined,
  isAssociatedTo: (state) => (shoppingListId: string) => state.previsionnel.associateShoppingListId === shoppingListId,
}

function getStored() {
  const stored = getLocalStorageJSON(PREVISIONNEL_LOCAL_STORAGE_KEY)
  if (stored) {
    const { productList, ...previsionnel } = stored
    return { productList, previsionnel }
  }
  return undefined
}

const previsionnel = {
  namespaced: true,
  state: getStored() ?? structuredClone(initialState),
  mutations: previsionnelMutations,
  actions: {},
  getters: previsionnelGetters,
}
export default previsionnel
