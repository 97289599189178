var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"adn-input-number"},[_c('label',{staticClass:"adn-input-number__label",class:{
      'adn-input-number__label--disabled': _vm.disabled,
      'adn-input-number__label--error': _vm.errorMessage,
    },attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"adn-input-number__wrapper",class:{
      'adn-input-number__wrapper--disabled': _vm.disabled,
      'adn-input-number__wrapper--error': _vm.errorMessage,
    }},[_c('input',{ref:"adnInputNumber",staticClass:"adn-input-number__input",class:{
        'adn-input-number__input--disabled': _vm.disabled,
        'adn-input-number__input--error': _vm.errorMessage,
      },attrs:{"name":_vm.name,"required":_vm.required,"id":_vm.id,"type":"number","placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"disabled":_vm.disabled,"step":_vm.step,"inputmode":"decimal","aria-invalid":_vm.errorMessage ? 'true' : false,"aria-describedby":_vm.errorMessage ? `error${_vm.id}` : undefined},domProps:{"value":_vm.value},on:{"input":_vm.internalEmit}}),_c('span',[_vm._v(_vm._s(_vm.unit))])]),(_vm.errorMessage)?_c('div',{staticClass:"adn-input-number__error",attrs:{"data-test-error":""}},[_c('span',{staticClass:"adn-icon adn-icon--status-unavailable",attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"id":`error${_vm.id}`}},[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }