import type { ShoppingList } from '../domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

interface OrderShoppingListUseCase {
  (shoppingListId: string): Promise<ShoppingList>
}

export function createOrderShoppingListUseCase({ shoppingListRepository }: Dependencies): OrderShoppingListUseCase {
  return async (shoppingListId) => {
    return await shoppingListRepository.orderShoppingList(shoppingListId)
  }
}
