import type { SaveLinePayloadApi, ShoppingListApi } from '@aladin/shared-shopping-list-sdk'
import { Commit, RootGetters } from '@b2ag/store'
import { isSouffletPricerOfferId } from '@b2ag/soufflet-pricer'
import type { ShoppingListTotalDetails } from '@b2ag/shopping-list/src/domain/shopping-list.model'
import { shoppingListApiTotalDetailsToModel } from '@b2ag/shopping-list/src/infra/shopping-list.mappers'
import type {
  CurrentTargetShoppingList,
  TargetShoppingList,
  TargetShoppingListToList,
} from '@b2ag/target-membership/src/domain/target-membership.interface'
import {
  mapShoppingListToList,
  mapToTargetShoppingList,
} from '@b2ag/target-membership/src/infra/target-membership.mappers'
import { shoppingListApiClient } from '../services'

export interface ShoppingListContext {
  commit: Commit<typeof shoppingListStoreMutations>
  state: ShoppingListState
  dispatch: Dispatch
  rootGetters: Record<RootGetters, any>
  getters: typeof shoppingListStoreGetters
}

export type ShoppingListContextInitiator = 'previsionnel' | 'product'
type StateProperty = 'isLoading' | 'isSaving'

type ShoppingListState = {
  shoppingList: CurrentTargetShoppingList['shoppingList']
  apiShoppingList?: ShoppingListApi
  /**
   * SLR:
   * Le nommage de shoppingList*ToList* est atroce.
   */
  shoppingListToList: CurrentTargetShoppingList['shoppingListToList']
  isListInitialized: boolean
  initialOfferIds?: string[]
  /**
   * Besoin de savoir où la SL a été initiée
   */
  initiator?: ShoppingListContextInitiator
  /**
   * SLR:
   */
  totalDetails?: ShoppingListTotalDetails
  /**
   * Herité de /contexts/product/infra/product.controller.ts
   */
  isLoading: boolean
  isSaving: boolean
}

const initialShoppingListState = Object.freeze<ShoppingListState>({
  apiShoppingList: undefined,
  shoppingList: { id: '', title: '', numberOfItems: 0, totalWithTaxes: '0.00', lines: [] },
  shoppingListToList: { line_items: [], offerIds: [] },
  isListInitialized: false,
  isLoading: false,
  isSaving: false,
  initialOfferIds: [],
  totalDetails: {
    articleCount: 0,
    productCount: 0,
    totalWithTaxes: '0.00',
    totalWithoutTaxes: '0.00',
    totalWithoutVat: '0.00',
    tvaDetails: '0.00',
  },
})

const shoppingListStoreGetters = {
  shoppingList(state: ShoppingListState) {
    return state.shoppingList
  },
  apiShoppingList(state: ShoppingListState) {
    // using method-style getter to ensure reactivity
    return () => state.apiShoppingList
  },
  shoppingListToList(state: ShoppingListState) {
    return state.shoppingListToList
  },
  isListInitialized(state: ShoppingListState) {
    return state.isListInitialized
  },
  initialOfferIds(state: ShoppingListState) {
    return state.initialOfferIds
  },
  id(state: ShoppingListState) {
    return state.shoppingList?.id
  },
  title(state: ShoppingListState) {
    return state.shoppingList?.title
  },
  isLoading(state: ShoppingListState) {
    return state.isLoading
  },
  isSaving(state: ShoppingListState) {
    return state.isSaving
  },
  totalDetails(state: ShoppingListState) {
    return state.totalDetails
  },
  containsPricerOffers(state) {
    // order_reference n'existe pas donc on prend l'id que l'on doit retravailler pour avoir le bon format
    return state.shoppingList?.lines.some((item) => isSouffletPricerOfferId(item.offer.id))
  },
}

const shoppingListStoreMutations = {
  INIT(
    state: ShoppingListState,
    // shoppingList: ShoppingListApi,
    // shoppingListInitiator: ShoppingListContextInitiator = 'product'
  ) {
    state.isListInitialized = true
  },
  SET_SHOPPING_LIST(
    state: ShoppingListState,
    shoppingList: ShoppingListApi | TargetShoppingList,
    shoppingListInitiator: ShoppingListContextInitiator = 'product',
  ) {
    state.isListInitialized = true

    if ('total' in shoppingList) {
      state.totalDetails = shoppingListApiTotalDetailsToModel(shoppingList)
      state.apiShoppingList = shoppingList
    }

    state.shoppingList = mapToTargetShoppingList(shoppingList)

    state.initiator = shoppingListInitiator
  },
  SET_SHOPPING_LIST_TO_LIST(state: ShoppingListState, shoppingListToList: TargetShoppingListToList) {
    state.initialOfferIds = shoppingListToList?.offerIds || []

    /**
     * SLR:
     * nécessité de rajouter pre_shippings & line_items?
     */

    state.shoppingListToList = shoppingListToList
  },
  TOGGLE_STATE_PROP(state: ShoppingListState, { property, value }: { property: StateProperty; value: any }) {
    state[property] = value
  },
}

type Dispatch = <K extends keyof ShoppingListStoreActions>(
  key: K,
  ...args: Parameters<ShoppingListStoreActions[K]>
) => ReturnType<ShoppingListStoreActions[K]>

type ActionImplementation<T extends keyof ShoppingListStoreActions> = (
  context: ShoppingListContext,
  ...args: Parameters<ShoppingListStoreActions[T]>
) => ReturnType<ShoppingListStoreActions[T]>

export interface ShoppingListStoreActions {
  storeCurrentShoppingList: ({ shoppingList }: { shoppingList: TargetShoppingListToList }) => void
  removeCurrentShoppingList: () => void
}

/**
 * Challenger le { root: true }
 * cartV2
 */
interface LocalShoppingListStoreActions {
  'shop/setCartOffers': (offerIds: TargetShoppingListToList['offerIds'], root?: { root: boolean }) => void
}

const shoppingListStoreActions: {
  [K in keyof (ShoppingListStoreActions | LocalShoppingListStoreActions)]: ActionImplementation<K>
} = {
  resetShoppingList({ rootState, commit }) {
    // eslint-disable-next-line no-param-reassign
    rootState.shoppingList = JSON.parse(JSON.stringify(initialShoppingListState))
    commit('INIT')
  },
  storeCurrentShoppingList({ commit, dispatch }, { shoppingList, shoppingListToList }: CurrentTargetShoppingList) {
    commit('SET_SHOPPING_LIST', shoppingList)
    commit('SET_SHOPPING_LIST_TO_LIST', shoppingListToList)
    dispatch('shop/setCartOffers', shoppingListToList?.offerIds || [], { root: true })
  },
  async createShoppingListWithLine(
    { rootGetters, commit, dispatch },
    { lines }: { lines: SaveLinePayloadApi[]; shoppingListContext: ShoppingListContextInitiator },
  ) {
    commit('TOGGLE_STATE_PROP', { property: 'isSaving', value: true })
    const { partner_id, ...recipient } = rootGetters.targetCustomer
    const { id } = await shoppingListApiClient.saveShoppingList({ lines, partner_id, recipient })

    /**
     * SLR:
     * The pattern.
     */
    await shoppingListApiClient.addLineToShoppingList(id, lines[0])
    const shoppingList = await shoppingListApiClient.fetchOwnedShoppingList(id)
    const shoppingListToList = mapShoppingListToList(shoppingList!)
    commit('SET_SHOPPING_LIST', shoppingList)
    commit('SET_SHOPPING_LIST_TO_LIST', shoppingListToList)

    dispatch('shop/setCartOffers', shoppingListToList?.offerIds || [], { root: true })
    commit('TOGGLE_STATE_PROP', { property: 'isSaving', value: false })
  },
  async addLineToShoppingList({ getters, commit, dispatch }, line: SaveLinePayloadApi) {
    commit('TOGGLE_STATE_PROP', { property: 'isSaving', value: true })
    /**
     * SLR:
     * The pattern.
     */
    await shoppingListApiClient.addLineToShoppingList(getters.id, line)
    const shoppingList = await shoppingListApiClient.fetchOwnedShoppingList(getters.id)
    const shoppingListToList = mapShoppingListToList(shoppingList!)
    commit('SET_SHOPPING_LIST', shoppingList)
    commit('SET_SHOPPING_LIST_TO_LIST', shoppingListToList)

    dispatch('shop/setCartOffers', shoppingListToList?.offerIds || [], { root: true })
    commit('TOGGLE_STATE_PROP', { property: 'isSaving', value: false })
  },
  async fetchShoppingList({ commit, dispatch }, { id }) {
    commit('TOGGLE_STATE_PROP', { property: 'isLoading', value: true })
    try {
      const shoppingList = await shoppingListApiClient.fetchOwnedShoppingList(id)
      const shoppingListToList = mapShoppingListToList(shoppingList!)
      commit('SET_SHOPPING_LIST', shoppingList)
      commit('SET_SHOPPING_LIST_TO_LIST', shoppingListToList)
      dispatch('shop/setCartOffers', shoppingListToList?.offerIds || [], { root: true })
    } finally {
      commit('TOGGLE_STATE_PROP', { property: 'isLoading', value: false })
    }
  },
  async deleteVariants({ commit, getters }, { variantIdSet }) {
    commit('TOGGLE_STATE_PROP', { property: 'isSaving', value: true })
    const shoppingListApi = getters.apiShoppingList()
    try {
      shoppingListApi.lines = shoppingListApi.lines?.filter((line) => !variantIdSet?.has(line.variant.variant_id))
      const updatedShoppingListApi = await shoppingListApiClient.saveShoppingList(shoppingListApi)
      commit('SET_SHOPPING_LIST', updatedShoppingListApi)
      const shoppingListToList = mapShoppingListToList(updatedShoppingListApi!)
      commit('SET_SHOPPING_LIST_TO_LIST', shoppingListToList)
    } finally {
      commit('TOGGLE_STATE_PROP', { property: 'isSaving', value: false })
    }
  },
  async removeCurrentShoppingList(context) {
    const currentShoppingListId = context.getters.id
    await shoppingListApiClient.removeShoppingList(currentShoppingListId)
    await context.dispatch('resetShoppingList')
  },

  // eslint-disable-next-line func-names
  'shop/setCartOffers': function (
    _context: ShoppingListContext,
    _offerIds: TargetShoppingListToList['offerIds'],
    _root?: { root: boolean },
  ): void {},
}

const shoppingList = {
  namespaced: true,
  state: structuredClone(initialShoppingListState),
  getters: shoppingListStoreGetters,
  mutations: shoppingListStoreMutations,
  actions: shoppingListStoreActions,
}
export default shoppingList
