import type { MembershipApi } from '@b2ag/membership'
import type { TargetShoppingListToList } from '@b2ag/target-membership/src/domain/target-membership.interface'
import type { ShoppingList } from '../domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

interface DuplicateShoppingListUseCase {
  (params: { partnerId: number; shoppingListId: string; recipient: MembershipApi }): Promise<{
    shoppingList: ShoppingList
    shoppingListToList: TargetShoppingListToList
  }>
}

export function createDuplicateShoppingListUseCase({
  shoppingListRepository,
}: Dependencies): DuplicateShoppingListUseCase {
  return async (params: {
    partnerId: number
    shoppingListId: string
    recipient: MembershipApi
  }): Promise<{ shoppingList: ShoppingList; shoppingListToList: TargetShoppingListToList }> => {
    return await shoppingListRepository.duplicateShoppingList(params)
  }
}
