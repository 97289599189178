import type { AxiosInstance } from '@b2ag/axios'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { CooperativeProduct, FlattenedPackProduct, Product, PRODUCT_TYPES } from '@b2ag/product/src/services/product'
import { mapFromApi as mapProductFromApi } from '@b2ag/product/src/services/product.mapper'
import { mapFromApi as mapProductListFromApi } from '@b2ag/product/src/services/productList.mapper'
import { CooperativeProductResponse, ProductGuestResponse, ProductInPackSchema } from '@b2ag/types-api-schemas'
import { buildCallerId } from '@b2ag/utils/src/caller-id'

export const FETCH_PRODUCT_ERROR = 'FETCH_PRODUCT_ERROR'

class ProductService {
  private readonly api: AxiosInstance

  constructor({ productApiHost }) {
    this.api = createAxiosClientWithAladinBearer(`${productApiHost}/v1`, {
      callerId: buildCallerId(),
    })
  }

  async findProductId(eanCode): Promise<number | undefined> {
    const response = await this.api.get<ProductGuestResponse[]>('/products', {
      params: { ean_code: eanCode },
    })
    const products = response.data
    const { _id: id }: any = products[0] || {}
    return id
  }

  async get(id, cooperativeId): Promise<CooperativeProduct> {
    const response = await this.api.get<CooperativeProductResponse>(`/cooperatives/${cooperativeId}/products/${id}`)
    const product = mapProductFromApi(response.data)
    return ProductService.isPackProduct(product)
      ? ProductService.flattenPackProduct(product, response.data.products_in_pack!)
      : product
  }

  async getCatalog(id: string, cooperativeId: string): Promise<CooperativeProduct> {
    try {
      const response = await this.api.get<CooperativeProductResponse>(`/cooperatives/${cooperativeId}/products/${id}`)
      const product = mapProductFromApi(response.data)
      return ProductService.isPackProduct(product)
        ? ProductService.flattenPackProduct(product, response.data.products_in_pack!)
        : product
    } catch (e: any) {
      throw new Error(FETCH_PRODUCT_ERROR)
    }
  }

  static isPackProduct(product: CooperativeProduct): boolean {
    return product.type === PRODUCT_TYPES.PACK
  }

  static flattenPackProduct(
    packProduct: Product | CooperativeProduct,
    productsInPack: ProductInPackSchema[],
  ): FlattenedPackProduct {
    const flattenedProduct = { ...packProduct } as any as FlattenedPackProduct

    const fieldsToFlatten = [
      'formulation',
      'market_authorization',
      'toxicities',
      'usages_by_crops',
      'active_substances',
    ]

    fieldsToFlatten.forEach((field) => {
      flattenedProduct[field] = productsInPack!.map((product) => {
        if (field === 'usages_by_crops') {
          return { id: product.id, product_name: product.name, value: product[field] }
        }
        return { product_name: product.name, value: product[field] }
      })
    })

    // eslint-disable-next-line camelcase
    flattenedProduct.safetySheetUrls = productsInPack!
      // eslint-disable-next-line camelcase
      .filter((product) => product.safety_sheet_urls)
      .map((product) => ({
        product_name: product.name,
        // eslint-disable-next-line camelcase
        value: product.safety_sheet_urls,
      }))

    return flattenedProduct
  }

  async getHighlighted(cooperativeId: number): Promise<CooperativeProduct[]> {
    const response = await this.api.get<CooperativeProductResponse[]>(
      `/cooperatives/${cooperativeId}/products?highlighted=true`,
    )
    return mapProductListFromApi(response.data)
  }

  async findProducts(idList: string[], cooperativeId: number): Promise<CooperativeProduct[]> {
    const response = await this.api.get<CooperativeProductResponse[]>(`/cooperatives/${cooperativeId}/products`, {
      params: { _id: idList.join(',') },
    })
    return response.data.map((responseProduct) => {
      const product = mapProductFromApi(responseProduct)
      return ProductService.isPackProduct(product)
        ? ProductService.flattenPackProduct(product, responseProduct.products_in_pack!)
        : product
    })
  }
}

export default ProductService
