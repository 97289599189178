import type { ShoppingList, ShoppingListItem } from '../domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

export function createRemoveLineFromShoppingListUsecase(deps: Dependencies): RemoveLineFromShoppingListUsecase {
  const { shoppingListRepository } = deps

  return async (shoppingList: ShoppingList, lineToRemove: ShoppingListItem): Promise<ShoppingList> => {
    return await shoppingListRepository.saveShoppingList({
      ...shoppingList,
      lines: shoppingList.lines.filter((item) => item.offer.id !== lineToRemove.offer.id),
    })
  }
}

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

export interface RemoveLineFromShoppingListUsecase {
  (shoppingList: ShoppingList, lineToRemove: ShoppingListItem): Promise<ShoppingList>
}
