import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

interface RemoveShoppingListUseCase {
  (shoppingListId: string): Promise<void>
}

export function createRemoveShoppingListUseCase({ shoppingListRepository }: Dependencies): RemoveShoppingListUseCase {
  return async (shoppingListId) => {
    return await shoppingListRepository.removeShoppingList(shoppingListId)
  }
}
