import type { MembershipFilter } from '@b2ag/membership'
import type { MembershipRepository } from '../infra/membership.repository'
import type { Pagination, Filter, Paginated, Membership } from '../domain/membership.interface'

interface Usecase {
  (
    partnerId: number,
    technicianUid: string,
    filter?: MembershipFilter,
    pagination?: Pagination,
  ): Promise<Paginated<Membership>>
}

export function getMembershipsWithShoppingLists(membershipRepository: MembershipRepository): Usecase {
  return async (partnerId, technicianUid, filter = DEFAULT_FILTER, pagination = DEFAULT_PAGINATION) => {
    return await membershipRepository.fetchMembershipWithShoppingLists(partnerId, technicianUid, filter, pagination)
  }
}

const DEFAULT_PAGINATION: Pagination = {
  page: 0,
  limit: 10,
}

const DEFAULT_FILTER: Filter = {
  search: '',
}
