import { AddressReactivitySettings } from '@b2ag/soufflet-pricer'
import type { Store } from '@b2ag/store'

export function makeAddressReactivitySettings(store: Store): AddressReactivitySettings {
  return {
    cartTypeLabel: 'devis',
    shouldRevalidate: () => store.getters['shoppingList/containsPricerOffers'],
    onRevalidatedConfirm: () => store.dispatch('shoppingList/removeCurrentShoppingList'),
  }
}
