import isEmpty from 'lodash/isEmpty'
import toInteger from 'lodash/toInteger'
import toString from 'lodash/toString'

export function mapFromApi(cooperative = {}) {
  const {
    id,
    name,
    customer_support_opening_hours: openingHours = '',
    customer_support_phone_no: phone = '',
    customer_support_email: email = '',
  } = cooperative

  if (isEmpty(toString(id))) return {}

  return {
    id: toString(id),
    name,
    customerSupport: { openingHours, phone, email },
  }
}

export function mapToApi(cooperative = {}) {
  const { id, name, membershipNumber } = cooperative

  if (isEmpty(toString(id))) return {}

  return {
    id: toInteger(id),
    name,
    membership_number: membershipNumber,
  }
}
