import { inject, provide } from 'vue'

export type MembershipChangedParams = string

export type MembershipReactions = {
  processChanged(params: MembershipChangedParams): Promise<void>
}

/**
 *
 * Internal provide/inject with defaults
 */

/**
 * MembershipReactions internal provide/inject key.
 * @internal
 * @remarks Should only be used externally in tests to mock the provided reactions.
 * @prefer {@link useMembershipReactions().provide()} or {@link useMembershipReactions().inject()}
 */
export const membershipReactionsKey = Symbol('MembershipReactions')
const noop = async () => {}

function provideMembershipReactions(callbacks: MembershipReactions): void {
  provide(membershipReactionsKey, callbacks)
}

function injectMembershipReactions(): MembershipReactions {
  return (
    inject(membershipReactionsKey) ?? {
      processChanged: noop,
    }
  )
}

/**
 * Public `use`
 */
export type MembershipReactionsHandle = {
  provide: (callbacks: MembershipReactions) => void
  inject: () => MembershipReactions
}

export function useMembershipReactions(): MembershipReactionsHandle {
  return {
    provide: provideMembershipReactions,
    inject: injectMembershipReactions,
  }
}
