import { inject, provide } from 'vue'

/**
 * Semantic alias
 */
type ShoppingListId = string
type Reaction<P = void, R = void> = (parameters: P) => Promise<R>
const noop: Reaction<unknown> = async () => {}

export type ShoppingListUpdateSucceededParams = ShoppingListId
export type ShoppingListUpdateSucceededCallback = Reaction<ShoppingListUpdateSucceededParams>

export type ShoppingListUpdateFailedParams = {
  shoppingListId: ShoppingListId
  error: any
}
export type ShoppingListUpdateFailedCallback = Reaction<ShoppingListUpdateFailedParams>

export type ShoppingListSentParams = void
export type ShoppingListSentCallback = Reaction<ShoppingListSentParams>

export type ShoppingListRemovedParams = void
export type ShoppingListRemovedCallback = Reaction<ShoppingListRemovedParams>

/*
 * Internal symbols
 */
const shoppingListReactionsKeyPrefix = 'ShoppingListReactions'

/**
 * ShoppingListReactions.UpdateSucceeded internal provide/inject key.
 * @internal
 * @remarks Should only be used externally in tests to mock the provided reactions.
 * @prefer {@link useShoppingListReactions().provideUpdateSucceeded()} or {@link useShoppingListReactions().injectUpdateSucceeded()}
 */
export const shoppingListUpdateSucceededReactionKey = Symbol(`${shoppingListReactionsKeyPrefix}.UpdateSucceeded`)

/**
 * ShoppingListReactions.UpdateFailed internal provide/inject key.
 * @internal
 * @remarks Should only be used externally in tests to mock the provided reactions.
 * @prefer {@link useShoppingListReactions().provideUpdateFailed()} or {@link useShoppingListReactions().injectUpdateFailed()}
 */
export const shoppingListUpdateFailedReactionKey = Symbol(`${shoppingListReactionsKeyPrefix}.UpdateFailed`)

/**
 * ShoppingListReactions.Sent internal provide/inject key.
 * @internal
 * @remarks Should only be used externally in tests to mock the provided reactions.
 * @prefer {@link useShoppingListReactions().provideSent()} or {@link useShoppingListReactions().injectSent()}
 */
export const shoppingListSentReactionKey = Symbol(`${shoppingListReactionsKeyPrefix}.Sent`)

/**
 * ShoppingListReactions.Removed internal provide/inject key.
 * @internal
 * @remarks Should only be used externally in tests to mock the provided reactions.
 * @prefer {@link useShoppingListReactions().provideRemoved()} or {@link useShoppingListReactions().injectRemoved()}
 */
export const shoppingListRemovedReactionKey = Symbol(`${shoppingListReactionsKeyPrefix}.Removed`)

/*
 * Public `use` API
 */
export type ShoppingListReactionsHandle = {
  provideUpdateSucceeded: (callback: ShoppingListUpdateSucceededCallback) => void
  injectUpdateSucceeded: () => ShoppingListUpdateSucceededCallback
  provideUpdateFailed: (callback: ShoppingListUpdateFailedCallback) => void
  injectUpdateFailed: () => ShoppingListUpdateFailedCallback
  provideSent: (callback: ShoppingListSentCallback) => void
  injectSent: () => ShoppingListSentCallback
  provideRemoved: (callback: ShoppingListRemovedCallback) => void
  injectRemoved: () => ShoppingListRemovedCallback
}

export function useShoppingListReactions(): ShoppingListReactionsHandle {
  return {
    provideUpdateSucceeded: (callback) => provide(shoppingListUpdateSucceededReactionKey, callback),
    injectUpdateSucceeded: () => inject(shoppingListUpdateSucceededReactionKey) ?? noop,
    provideUpdateFailed: (callback) => provide(shoppingListUpdateFailedReactionKey, callback),
    injectUpdateFailed: () => inject(shoppingListUpdateFailedReactionKey) ?? noop,
    provideSent: (callback) => provide(shoppingListSentReactionKey, callback),
    injectSent: () => inject(shoppingListSentReactionKey) ?? noop,
    provideRemoved: (callback) => provide(shoppingListRemovedReactionKey, callback),
    injectRemoved: () => inject(shoppingListRemovedReactionKey) ?? noop,
  }
}
