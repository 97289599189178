import type { Membership, ShoppingListInfo } from '../domain/membership.interface'
import type { MembershipRepository } from '../infra/membership.repository'

interface Usecase {
  (partnerId: number, membership: Membership): Promise<ShoppingListInfo>
}

export function createCreateShoppingListForMembership(membershipRepository: MembershipRepository): Usecase {
  return async (partnerId, membership) => {
    return await membershipRepository.createShoppingList(partnerId, membership)
  }
}
