export interface Previsionnel {
  _id: string
  userCode: string
  thirdCode: string
  farmCode: string
  creationDate: string
  cropYear: string
  productList: PrevisionnelProductPayload[]
  associateShoppingListId?: string
}

export interface PrevisionnelProductPayload {
  productCode: string
  productName: string
  quantity: number
  unit: string
  replaced?: boolean
  variants?: InvertedVariantHit[]
}

export interface InvertedVariantHit {
  id: string
  product: { _id: string }
  previsionnelProductCode: string
}

export type AttributedPrevisionnel = Previsionnel & { membershipCode?: string }

type PrevisionnelProductWithVariants = PrevisionnelProductPayload & {
  variants: InvertedVariantHit[]
}

export function previsionnelHasVariants(
  productList: PrevisionnelProductPayload[],
): productList is PrevisionnelProductWithVariants[] {
  return productList.every((product) => 'variants' in product)
}
