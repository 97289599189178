import Vue from 'vue'
import Router from 'vue-router'
import { i18n } from '@b2ag/locale'
import { AuthErrorKeys, useAuthService } from '@b2ag/auth'
import { VAuthCallback, VAuthCallbackError } from '@b2ag/auth/dist/auth-views.umd'
import { locationStorage } from '@/services'
// eslint-disable-next-line import/no-cycle
import VMicroFrontProvider from '@b2ag/micro-front-tools/src/providers/VMicroFrontProvider.vue'
import type { DataForParent } from '@b2ag/micro-front-tools'
import { callbacksFromLegalsMicroFrontIframe } from '@/contexts/legals'
import { isWebViewFarmi } from '@b2ag/farmi-app-appro-tools'
import { useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import { useCurrentMembership } from '@b2ag/membership/src/composables/useCurrentMembership'
import { routingCancelCheckout } from '@b2ag/checkout'
import { useShoppingList } from '@b2ag/shopping-list/src/composables/useShoppingList'
import store from './store/store'
import { trackPageView } from './composables/usePageAnalytics'

const PAGE_TYPE = {
  HOME: 'Home',
  SEARCH: 'Recherche',
  PRODUCT_PAGE: 'Page produit',
  ADHERENTS: 'Adhérents',
  SHOPPING_LISTS: 'Listes',
  ORDERS: 'Commandes',
  CHECKOUT: 'Checkout',
  CGU: 'CGU',
  AUTH: 'oauth',
  MISC: 'Divers',
}

const PAGE_ANALYTICS_INFO = {
  HOME: { name: 'Home', category: PAGE_TYPE.HOME },
  SEARCH_RESULTS: { name: 'Résultats de recherche', category: PAGE_TYPE.SEARCH },
  PRODUCT_PAGE: { name: 'Détails page produit', category: PAGE_TYPE.PRODUCT_PAGE },
  ADHERENTS_LIST: { name: 'Listing des adhérents', category: PAGE_TYPE.ADHERENTS },
  ADHERENT_SHOPPING_LISTS: { name: "Listes d'un adhérent", category: PAGE_TYPE.ADHERENTS },
  ADHERENT_DETAILS: { name: "Profil d'un adhérent", category: PAGE_TYPE.ADHERENTS },
  ADHERENT_ORDERS: { name: "Commandes d'un adhérent", category: PAGE_TYPE.ADHERENTS },
  TECHNICIAN_SHOPPING_LISTS: { name: 'Listes du technicien', category: PAGE_TYPE.SHOPPING_LISTS },
  LIST_DETAILS: { name: "Détails d'une liste", category: PAGE_TYPE.SHOPPING_LISTS },
  SEND_SHOPPING_LIST: { name: "Envoi d'une liste", category: PAGE_TYPE.SHOPPING_LISTS },
  ORDER_DETAILS: { name: "Détail d'une commande", category: PAGE_TYPE.ORDERS },
  ORDERS: { name: 'Liste de toutes les commandes', category: PAGE_TYPE.ORDERS },
  CHECKOUT: { name: 'Commande en cours', category: PAGE_TYPE.CHECKOUT },
  SMAG_SSO: { name: 'SMAG SSO', category: PAGE_TYPE.AUTH },
  OAUTH_CALLBACK: { name: 'Auth Callback', category: PAGE_TYPE.AUTH },
  AUTH: { name: 'oauth', category: PAGE_TYPE.AUTH },
  OAUTH_SILENT_CALLBACK: { name: 'oauth silent callback', category: PAGE_TYPE.AUTH },
  CGU: { name: 'Accepter CGU', category: PAGE_TYPE.CGU },
  NOT_FOUND: { name: 'page non trouvée', category: PAGE_TYPE.MISC },
}

/* ------- PARENT - LAYOUT ------- */

const VMainLayout = () => import(/* webpackChunkName: "MainLayout.route" */ './layouts/VMainLayout/VMainLayout.vue')

const VMinimalLayout = () =>
  import(/* webpackChunkName: "VMinimalLayout.route" */ './layouts/VMinimalLayout/VMinimalLayout.vue')

/* ------- PARENT - CORE ------- */

const VAppSearch = () => import(/* webpackChunkName: "VAppSearch.route" */ './views/VAppSearch.vue')
const VNotFound = () => import(/* webpackChunkName: "VNotFound.route" */ '@b2ag/views/src/VNotFound/VNotFound.vue')
const VProductMembership = () =>
  import(/* webpackChunkName: "VProductMembership.route" */ './views/VProductMembership.vue')

const coreRoutes = [
  {
    path: '',
    name: 'home',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'dashboard-tech',
        viewName: 'home',
        router,
        analyticService: (() => useAnalyticService())(),
      }
    },
    beforeEnter(_, __, next) {
      if (process.env.VUE_APP_CONTEXT === 'farmi' && isWebViewFarmi()) {
        next({ name: 'search' })
      } else {
        next()
      }
    },

    meta: {
      isSearchPage: false,
      showSelectedCustomer: true,
      changeSelectedCustomer: true,
      showWelcomeHeader: true,
      showAppFooter: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.HOME,
    },
  },
  {
    path: 'recherche',
    name: 'search',
    component: VAppSearch,
    meta: {
      isSearchPage: true,
      showSelectedCustomer: true,
      changeSelectedCustomer: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.SEARCH_RESULTS,
    },
  },
  {
    path: 'category/:slug',
    name: 'category',
    component: VAppSearch,
    props: (route) => {
      return { categorySlug: route.params.slug }
    },
    meta: {
      isSearchPage: true,
      showSelectedCustomer: true,
      changeSelectedCustomer: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.SEARCH_RESULTS,
    },
  },
  {
    path: 'produits/:productId',
    name: 'product',
    component: VProductMembership,
    meta: {
      showSelectedCustomer: true,
      changeSelectedCustomer: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.PRODUCT_PAGE,
    },
  },
]

/* ------- CONTEXTS - AUTH ------- */

// voir dans les imports

const authRoutes = [
  {
    path: '/oauth/login/smag',
    name: 'smag-sso',
    meta: {
      public: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.SMAG_SSO,
    },
    beforeEnter: () => {
      const authService = useAuthService()
      authService.login({ target: 'SMAG' })
    },
  },
  {
    path: '/oauth/callback',
    name: 'auth-callback',
    meta: {
      public: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.OAUTH_CALLBACK,
    },
    component: VAuthCallback,
    props() {
      return { targetRoute: locationStorage.afterLoginPath, router, auth: useAuthService() }
    },
  },
  {
    path: '/oauth/error',
    name: 'auth-callback-error',
    meta: {
      public: true,
    },
    component: VAuthCallbackError,
    props(route) {
      return { errorCode: route.query.errorCode, auth: useAuthService(), appName: i18n._('aladin') }
    },
  },
]

/* ------- MICROFRONT - CHECKOUT ------- */

const checkoutRoutes = [
  {
    path: '/liste/:shoppingListId/commande/:checkoutId',
    name: 'buy-layout',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'checkout',
        routeName: `tech/${router.currentRoute.params.shoppingListId}/commande/${router.currentRoute.params.checkoutId}`,
        router,
        analyticService: (() => useAnalyticService())(),
      }
    },
    meta: {
      analyticsPageInfo: PAGE_ANALYTICS_INFO.CHECKOUT,
    },
  },
]

/* ------- MICROFRONT - MEMBERSHIP ------- */

const membershipRoutes = [
  {
    path: 'adherents/:membershipNumber',
    name: 'membership-detail',
    component: VMicroFrontProvider,
    props: (route) => {
      return {
        microFrontName: 'membership',
        routeName: `details/${route.params.membershipNumber}`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.ADHERENT_DETAILS,
    },
  },
  {
    path: 'adherents-liste',
    name: 'adherents-list',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'membership',
        routeName: `adherents-liste`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: true,
      changeSelectedCustomer: true,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.ADHERENTS_LIST,
    },
  },
]

/* ------- MICROFRONT - SHOPPING LIST ------- */

const VShoppingListDetails = () =>
  import(/* webpackChunkName: "VShoppingListDetails.route" */ './views/VShoppingListDetails.vue')

const listsRoutes = [
  {
    path: 'technicien-liste',
    name: 'technician-shopping-lists',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'shopping-list',
        routeName: `all-tech-lists`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.TECHNICIAN_SHOPPING_LISTS,
    },
  },
  {
    path: 'adherents-liste/:id',
    name: 'adherents-list-details',
    component: VShoppingListDetails,
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.LIST_DETAILS,
    },
  },
  {
    path: 'adherents/:membershipNumber/liste',
    name: 'membership-shopping-lists',
    component: VMicroFrontProvider,
    props: (route) => {
      return {
        microFrontName: 'shopping-list',
        routeName: `list/${route.params.membershipNumber}`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.ADHERENT_SHOPPING_LISTS,
    },
  },
  {
    path: 'envoi-liste',
    name: 'send-shopping-list',
    component: VMicroFrontProvider,
    props: () => {
      const shoppingList = useShoppingList()
      return {
        microFrontName: 'shopping-list',
        routeName: `sent/${shoppingList.state.current?.id}`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.SEND_SHOPPING_LIST,
    },
  },
]

/* ------- MICROFRONT - ORDERS ------- */

const ordersRoutes = [
  {
    path: 'commandes',
    name: 'orders',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'orders',
        routeName: `tech/${store.getters.user.technical_sale_information.uuid}`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.ORDERS,
    },
  },
  {
    path: 'adherents/:membershipNumber/commandes',
    name: 'membership-orders',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'orders',
        routeName: `tech/${store.getters.user.technical_sale_information.uuid}/membership/${router.currentRoute.params.membershipNumber}`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.ADHERENT_ORDERS,
    },
  },
  {
    path: 'adherents/:membershipNumber/commandes/:orderId',
    name: 'order-detail',
    component: VMicroFrontProvider,
    props: () => {
      return {
        microFrontName: 'orders',
        routeName: `tech/${store.getters.user.technical_sale_information.uuid}/details/${router.currentRoute.params.orderId}`,
        router,
      }
    },
    meta: {
      showSelectedCustomer: false,
      changeSelectedCustomer: false,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.ORDER_DETAILS,
    },
  },
]

/* ------- CONTEXTS - SMAG ------- */

const VSmagLayout = () => import(/* webpackChunkName: "VSmagLayout.route" */ '@/layouts/VSmagLayout/VSmagLayout.vue')
const VSmagPrevisionnel = () =>
  import(
    /* webpackChunkName: "VSmagPrevisionnel.route" */ '@/contexts/previsionnel/application/views/VSmagPrevisionnel.vue'
  )

const smagRoutes = [
  {
    path: '/previsionnels',
    name: 'previsionnel',
    component: VSmagLayout,
    children: [
      {
        path: ':previsionnelId',
        name: 'smag-previsionnel',
        meta: {
          storePathBeforeAuth: true,
        },
        component: VSmagPrevisionnel,
        beforeEnter: (to, _from, next) => {
          const { previsionnelId } = to.params
          const mongoObjectIdRegex = /^[0-9a-fA-F]{24}$/
          const isValidMongoObjectId = (id: string) => {
            return mongoObjectIdRegex.test(id)
          }
          if (!isValidMongoObjectId(previsionnelId)) next({ name: 'not-found' })
          next()
        },
        props(route) {
          const { previsionnelId } = route.params
          return { previsionnelId }
        },
      },
    ],
  },
]

/* ------- CONTEXTS - ARTICLES ------- */
const VArticlePage = () =>
  import(
    /* webpackChunkName: "VArticlePage.route" */ '@b2ag/articles/src/application/view/VArticlePage/VArticlePage.vue'
  )

const articlesRoutes = [
  {
    path: '/article',
    component: VMainLayout,
    children: [
      {
        path: ':slug',
        name: 'article-page',
        component: VArticlePage,
        props: () => ({
          excludeComponents: ['newsletter', 'breadcrumb', 'tags-links', 'social-share'],
        }),
      },
    ],
  },
]

/* ------- MICROFRONT - LEGALS ------- */

const legalsRoutes = [
  {
    path: '/accept-cgu',
    component: VMinimalLayout,
    meta: {
      analyticsPageType: PAGE_TYPE.CGU,
      analyticsPageInfo: PAGE_ANALYTICS_INFO.CGU,
    },
    children: [
      {
        path: '/',
        name: 'accept-cgu',
        component: VMicroFrontProvider,
        props: () => {
          return {
            microFrontName: 'legals',
            routeName: 'accept-cgu',
            callbackForMessages: (data: DataForParent) => callbacksFromLegalsMicroFrontIframe(data, router),
          }
        },
        beforeEnter: async (_to, _from, next) => {
          if (store.getters.isCGUAccepted) {
            router.push({ name: 'home' })
          } else {
            next()
          }
        },
      },
    ],
  },
]

/* -------------------------------------------- */

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    ...checkoutRoutes,
    ...smagRoutes,
    ...authRoutes,
    ...legalsRoutes,
    ...articlesRoutes,
    {
      path: '/',
      component: VMainLayout,
      props(route) {
        return {
          showSelectedCustomer: route?.meta?.showSelectedCustomer ?? false,
          changeSelectedCustomer: route?.meta?.changeSelectedCustomer ?? false,
          showWelcomeHeader: route?.meta?.showWelcomeHeader ?? false,
          showAppFooter: route?.meta?.showAppFooter ?? false,
        }
      },
      children: [...coreRoutes, ...listsRoutes, ...membershipRoutes, ...ordersRoutes],
    },
    {
      path: '*',
      component: VMainLayout,
      props() {
        return {
          showWelcomeHeader: false,
          showAppFooter: true,
        }
      },
      children: [
        {
          path: '*',
          name: 'not-found',
          component: VNotFound,
          meta: {
            analyticsPageInfo: PAGE_ANALYTICS_INFO.NOT_FOUND,
          },
        },
      ],
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

export async function routerBeforeEach(storeParam, to, from, next) {
  // Refresh without the History API whenever the user triggers a page change
  // if a new version of the app has been deployed recently.
  if (storeParam.getters.isOutdated) {
    window.location.assign(to.fullPath)
    return false
  }

  if (to.meta.storePathBeforeAuth || to.query?.forceLogin) {
    locationStorage.storeLocation(to.path)
  }

  if (to.meta.public) {
    return next()
  }

  const authService = useAuthService()

  try {
    await authService.checkUserAuthSession()
  } catch (error: any) {
    if (error.message === AuthErrorKeys.IS_NOT_AUTHENTICATED) {
      await authService.login()
      return false
    }
    if (error.message === AuthErrorKeys.INVALID_SCOPE) {
      return next({ name: 'auth-callback-error', query: { errorCode: error.message } })
    }
  }

  if (!storeParam.getters.isUserLoaded) {
    await storeParam.dispatch('fetchUser')
  }
  if (!storeParam.getters.isCGUAccepted && to.name !== 'accept-cgu') {
    return next({ name: 'accept-cgu' })
  }

  if (!storeParam.getters.targetCustomer) {
    await useCurrentMembership().loadCurrentMembership()
  }

  await storeParam.dispatch('addresses/fetchAddresses')

  try {
    if (from.name === checkoutRoutes[0].name) await routingCancelCheckout(from.params.checkoutId)
  } catch (error) {}

  return next()
}

function routerAfterEach(storeInstance, to, from) {
  trackPageView(to, from, storeInstance)

  if (to.meta.storePathBeforeAuth || to.query?.forceLogin) {
    locationStorage.storeLocation('/')
  }
}

router.beforeEach((to, from, next) => routerBeforeEach(store, to, from, next))
router.afterEach((to, from) => routerAfterEach(store, to, from))

export const getRouter = () => router

export default router
