<template>
  <div id="all" :class="environmentClasses">
    <router-view id="app" />
    <p v-if="!isOnline" class="app__offline-message">Vous êtes actuellement hors ligne</p>
  </div>
</template>

<script lang="ts">
import { provideMediaService } from '@b2ag/medias'
import { provideRouter } from '@b2ag/composables'
import { provideStore } from '@b2ag/store'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { provideFocusIndex } from '@b2ag/composables/src/catalog/useDiscount'
import { computed, defineComponent } from 'vue'
import { getAppTypeLocale } from '@b2ag/locale'
import { provideAddressReactivitySettings } from '@b2ag/soufflet-pricer'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { isWebViewFarmi } from '@b2ag/farmi-app-appro-tools'
import { useCategoriesSlugs } from '@b2ag/search/src/composables/useCategoriesSlugs'
import { provideShoppingList } from '@b2ag/shopping-list/src/composables/useShoppingList'
import type { Store } from '@b2ag/store'
import { provideMembershipStore } from '@b2ag/membership'
import { provideCurrentMembership } from '@b2ag/membership/src/composables/useCurrentMembership'
import { getStore } from './store/store'
import { getRouter } from './router'
import { makeAddressReactivitySettings } from './contexts/soufflet-pricer'

const SANDBOX_ENVIRONMENT_NAMES = ['DEV', 'TEST', 'QUA', 'COOP', 'OPS']
const appTypeLocale = getAppTypeLocale(process.env.VUE_APP_CONTEXT)

export default defineComponent({
  name: 'App',
  setup() {
    window.env.APP_CONTEXT = process.env.VUE_APP_CONTEXT!
    window.env.FRONT_CONTEXT = 'tech'

    const store = getStore() as Store
    const mediaApiClient = createAxiosClientWithAladinBearer(`${window.env.MEDIA_API_HOST}/v1`, {
      callerId: buildCallerId(),
    })

    provideCurrentMembership(store)

    provideStore(store)
    provideRouter(getRouter())
    provideMediaService(mediaApiClient)
    provideMembershipStore(store)
    provideAddressReactivitySettings(makeAddressReactivitySettings(store))
    provideShoppingList()
    provideFocusIndex()

    useCategoriesSlugs()

    const isOnline = computed(() => store.getters.isOnline)
    const environmentClasses = computed(() => {
      const classes: string[] = []
      const env = window.env.ENVIRONMENT_NAME ?? 'DEV'
      if (SANDBOX_ENVIRONMENT_NAMES.includes(env)) {
        classes.push('all--sandbox')
        classes.push(`all--${env.toLowerCase()}`)
      } else {
        classes.push('all--production')
      }
      return classes
    })

    // XJU - 30/01/2024 : pas trouvé mieux pour le moment
    if (isWebViewFarmi()) {
      const styles = document.createElement('style')
      styles.setAttribute('type', 'text/css')
      styles.textContent = `:root { --mobile-header-height: 58px !important; }`
      document.head.appendChild(styles)
    }

    const styles = document.createElement('style')
    styles.setAttribute('type', 'text/css')
    styles.textContent = `:root { --desktop-header-height: 66px !important; }`
    document.head.appendChild(styles)

    return {
      isOnline,
      environmentClasses,
    }
  },
  metaInfo() {
    return {
      title: appTypeLocale.metadata.title,
    }
  },
})
</script>

<style lang="scss">
@import '@b2ag/themes/common/lib/bootstrap/index.scss';
@import '@b2ag/themes/common/styles/index.scss';
@import '@b2ag/themes/common/styles/root.scss';
@import '@b2ag/themes/current/styles/fonts.css';

html {
  height: 100%;
}

body {
  min-height: 100vh;
  height: 100%;
  color: $color-text;
  background: $color-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.p--regular {
  font-size: 16px;
  line-height: 22px;
}

.label--regular {
  font-size: 14px;
  line-height: 20px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  color: $color-main;

  &:hover {
    color: $color-main-dark;
  }
}

.btns {
  margin: 0.8rem 0;
}

.btn {
  border-radius: $border-radius-1;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.btn-full-screen {
  width: 100%;
  @media screen and (min-width: 576px) {
    margin: 0 1em 0 0;
    width: auto;
  }
}

.btn .spinner-border {
  vertical-align: middle;
  margin-left: 10px;
}

#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-position: center;
  background-size: cover;
}

#all {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
}

#minimal {
  flex: 1;

  .main {
    flex: 1 0 auto;
    height: 100vh;
    background: white;
    margin-top: 0;
    display: flex;
    align-items: center;

    & > div {
      flex: 1;
    }
  }
}

strong {
  font-weight: bold;
}

.app__offline-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px;
  background: $color-warning;
  color: $color-text-lightest;
  text-align: center;
  margin: 0;
  line-height: 30px;
  z-index: 1000;
}

.app__outdated-message {
  font-size: 1.1em;
  color: $color-main;
  cursor: pointer;
  position: fixed;
  bottom: 12px;
  left: 0;
  right: 0;
  width: 96vw;
  height: 70px;
  background: white;
  border: 4px solid $color-main;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  z-index: 1000;
  opacity: 0.94;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    height: 40px;
    font-size: 0.7em;

    & > p {
      margin-right: 0.4rem;
    }
  }
}

.emphasis__block {
  @media screen and (min-width: 576px) {
    padding: 1em;
    background-color: $color-background;
    color: $color-text;
  }
  padding: 2em 1em;
  background-color: $color-background-darkest;
  color: $color-background-light;
}

.modal-footer {
  .btn:not(:first-child) {
    margin-left: 0.4rem;
  }

  .btn:not(:last-child) {
    margin-right: 0.4rem;
  }
}

.modal-content {
  color: $color-text;
}

.main-title {
  font-size: 1.2em;
  font-weight: bold;
  color: $color-text;
  text-align: left;
  @media screen and (min-width: $breakpoint-lg) {
    text-align: center;
  }

  strong {
    text-transform: uppercase;
    color: $color-main;
  }
}

.container--full-width-on-mobile {
  @media screen and (max-width: 991px) {
    padding: 0;
  }
  @media screen and (max-width: 767px) {
    margin: 0;
    max-width: 100%;
  }
}

.container--full-width-on-tablet {
  @media screen and (max-width: 991px) {
    padding: 0;
  }
  @media screen and (max-width: 991px) {
    margin: 0;
    max-width: 100%;
  }
}

.content-block {
  @media screen and (min-width: 768px) {
    margin-bottom: 2em;
  }
}

.content-block--separated-on-mobile {
  border-bottom: 10px solid $color-border-light;
  @media screen and (min-width: 576px) {
    border-bottom: 1px solid $color-border-light;
  }
}

.form-check-label {
  cursor: pointer;
}

option:disabled {
  color: $color-inactive-light;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  color: $color-text;
  padding: 9px 12px;
  height: 41px;
  font-size: $font-size-s;
  border: 1px solid $color-border-light;
  border-radius: $border-radius-1;
  background-color: $color-background;
  box-shadow: none;

  &::placeholder {
    font-size: $font-size-s;
    color: $color-text-light;
    @media screen and (min-width: 576px) {
      font-size: 1.6rem;
    }
  }

  &:focus {
    box-sizing: border-box;
    border: 1px solid $color-text;
    box-shadow: none;
    outline: transparent;
  }

  &.login-form__input-error {
    border-color: $color-error;
  }
}

textarea {
  min-height: 64px;
  height: auto;
}

#all .v-select .open-indicator:before {
  border-width: 2px 2px 0 0;
}

#all .v-select .open-indicator {
  right: 20px;
  height: 1.7em;
}

#all .v-select .dropdown-toggle .clear {
  margin-right: 15px;
  font-weight: normal;
  font-size: 1.6em;
  line-height: 1.3em;
}

#price-detail-modal {
  .modal-content {
    border-radius: 0;
    border: none;
  }

  .modal-header {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.modal-content {
  border-radius: 0;
}
</style>
