import { useAuthService } from '@b2ag/auth'
import jwt from 'jsonwebtoken'

interface Cooperative {
  id: number
  name: string
}

interface Profile {
  uid: string
  cooperative: Cooperative
}

interface Role {
  type: string
  profiles: Profile[]
  permissions: string[]
}

export async function getUserTechRoleFromAccessToken(): Promise<Role | null> {
  const authService = useAuthService()
  const accessToken = await authService.getAccessToken()
  const decodedToken = jwt.decode(accessToken)
  if (decodedToken) {
    const roles = decodedToken[`${window.env.AUTH0_AUDIENCE}/v1/roles`] as Role[]
    const techRole = roles?.find((role) => role.type === 'tech')
    return techRole || null
  }
  return null
}
