import { createMembershipApiClient } from '@aladin/shared-universcoop-sdk'
import OfferService from '@b2ag/offers/src/services/offer.service'
import { AlgoliaSearchService } from '@b2ag/search'
import RecommendationService from '@b2ag/product/src/services/recommendation.service'
import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import { AuthLocationStorage, useAuthService } from '@b2ag/auth/dist/esm'
import algoliasearch from 'algoliasearch/lite'
import { useLogger } from '@b2ag/composables'
import { createShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { StockService } from '@b2ag/stock'
import { DiscountService } from '@b2ag/discount'
import { StoreService } from '@b2ag/coop-store'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { MembershipService } from '@b2ag/membership/src/services/membership.service'
import PrevisionnelService from './previsionnel.service'
import ProductService from './product.service'
import UserService from './user.service'

window.env = window.env || {}

const productApiHost = window.env.PRODUCT_API_HOST!
const userApiHost = window.env.USER_API_HOST!
const discountApiHost = window.env.DISCOUNT_API_HOST!
const orderApiHost = window.env.ORDER_API_HOST!
const searchAppId = window.env.ALGOLIA_APP_ID!
const searchApiKey = window.env.ALGOLIA_API_KEY!
const shoppingListApiHost = window.env.SHOPPING_LIST_API_HOST!

const accessTokenSource = () => useAuthService().getAccessToken()
const logger = useLogger()

export const algoliaClient = algoliasearch(searchAppId, searchApiKey)
export const searchService = new AlgoliaSearchService(algoliaClient)
export const membershipService = new MembershipService({ userApiHost })
export const productService = new ProductService({ productApiHost })
export const userService = new UserService(userApiHost)
export const recommendationService = new RecommendationService(productApiHost)
export const offerService = new OfferService(
  createAxiosClientWithAladinBearer(productApiHost, { callerId: buildCallerId() }),
)
export const discountService = new DiscountService({ discountApiHost })
export const stockService = new StockService(orderApiHost)
export const storeService = new StoreService({ userApiHost })
export const membershipApiClient = createMembershipApiClient({
  membershipApiHost: userApiHost,
  accessTokenSource,
  logger,
})
export const previsionnelService = new PrevisionnelService({ productApiHost })
export const locationStorage = new AuthLocationStorage(localStorage)
export const shoppingListApiClient = createShoppingListApiClient({
  shoppingListApiHost,
  useCartV2: true,
  accessTokenSource,
  logger,
})
