import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import type { AxiosInstance } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { isFarmi } from '@b2ag/app-brands'

export interface TechnicianDashboard {
  total_memberships_count: number
  registered_memberships_count: number
  unregistered_memberships_count: number
}

export class DashboardService {
  public userApi: AxiosInstance

  public reportingApi: AxiosInstance

  constructor({ userApiHost, reportingApiHost }) {
    this.userApi = createAxiosClientWithAladinBearer(`${userApiHost}/v1`, { callerId: buildCallerId() })
    this.reportingApi = createAxiosClientWithAladinBearer(`${reportingApiHost}/v1`, { callerId: buildCallerId() })
  }

  async getTechnicianDashboard(partnerId: number, technicianUid: string): Promise<TechnicianDashboard> {
    const { data } = await this.userApi.get<TechnicianDashboard>(
      `/partners/${partnerId}/technicians/${technicianUid}/dashboard`,
    )
    return data
  }

  async getTechnicianTotalCa(partnerId: number, techUid: string) {
    const { data } = await this.reportingApi.get('/technical-sales', {
      params: {
        tech_id: techUid,
        coop_id: partnerId,
      },
    })
    return data
  }

  async getLastsProductsViewed(partnerId: number, techUid: string, membershipNumber: string, limit: Number) {
    if (!isFarmi) {
      return this.userApi
        .get('/events/products-viewed/lasts', {
          params: {
            membership_number: membershipNumber,
            coop_id: partnerId,
            tech_uuid: techUid,
            limit,
          },
        })
        .then(({ data }) => data.response)
        .catch(() => [])
    }
    return Promise.resolve([])
  }
}
