import { useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import { Route } from 'vue-router'

function getUrl(thePath, base = window.location.origin): URL {
  const url = new URL(thePath, base)
  return url
}

function getPageInfo(route) {
  let pageType: { name: string; category: string } | undefined
  if (route.meta && route.meta.analyticsPageInfo) {
    pageType = route.meta.analyticsPageInfo
  } else if (route.matched && route.matched.length > 0) {
    const matchedRoutes = route.matched.slice().reverse()
    const firstParentWithPageType = matchedRoutes.find(
      (matchedRoute) => matchedRoute.meta && matchedRoute.meta.analyticsPageInfo,
    )
    if (firstParentWithPageType) {
      pageType = firstParentWithPageType.meta.analyticsPageInfo
    }
  }

  return pageType
}

export function trackPageView(to: Route, from: Route, store) {
  const urlTo = getUrl(to.fullPath)
  const urlFrom = getUrl(from.fullPath, document.referrer || undefined)
  const pageInfo = getPageInfo(to)

  const payload = {
    user: store?.getters?.analyticsUserPayload,
    page: {
      path: to.path,
      domain_name: urlTo.hostname,
      url: urlTo.toString(),
      referrer_path: from.path,
      referrer_domain_name: urlFrom.hostname,
      referrer_url: urlFrom.toString(),
      page_name: pageInfo?.name ?? to.name,
      page_category: pageInfo?.category ?? to.name,
      environment: window.env?.ENVIRONMENT_NAME,
      utm: {
        source: to.query?.utm_source,
        medium: to.query?.utm_medium,
        campaign: to.query?.utm_campaign,
      },
    },
  }
  useAnalyticService().trackEvent('page_view', payload)
}
