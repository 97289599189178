import type { ShoppingList } from '../domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
}

interface SendShoppingListUseCase {
  (shoppingListId: string): Promise<ShoppingList>
}

export function createSendShoppingListUseCase({ shoppingListRepository }: Dependencies): SendShoppingListUseCase {
  return async (shoppingListId: string): Promise<ShoppingList> => {
    return await shoppingListRepository.sendShoppingList(shoppingListId)
  }
}
