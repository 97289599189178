import type { Membership } from '@b2ag/membership'
import type { ShoppingListApi } from '@aladin/shared-shopping-list-sdk'
import type {
  TargetMembership,
  TargetShoppingList,
  TargetShoppingListToList,
} from '../domain/target-membership.interface'

export function mapToTargetMembership(membership: Membership): TargetMembership {
  return {
    membershipNumber: membership.membership_number,
    company: membership.company_name,
    fullName: membership.full_name,
    registered: membership.registered || false,
    warnings: membership.warnings,
  }
}

function isShoppingListApi(shoppingList: ShoppingListApi | TargetShoppingList): shoppingList is ShoppingListApi {
  return 'total' in shoppingList
}

export function mapToTargetShoppingList(shoppingList: ShoppingListApi | TargetShoppingList): TargetShoppingList {
  return {
    id: shoppingList.id,
    title: shoppingList.title,
    totalWithTaxes: isShoppingListApi(shoppingList) ? shoppingList.total : shoppingList.totalWithTaxes,
    numberOfItems: isShoppingListApi(shoppingList) ? shoppingList.lines.length : shoppingList.numberOfItems,
    lines: shoppingList.lines || [],
    subtotal: shoppingList.subtotal,
  }
}

export function mapShoppingListToList(shoppingList: ShoppingListApi): TargetShoppingListToList {
  const storeCodeLine = shoppingList.lines.find((line) => !!line.click_and_collect?.store_code)
  const storeCode = storeCodeLine?.click_and_collect?.store_code
  const lineItems = shoppingList.lines.map((line) => ({
    allowed_without_stock: line.offer.allowed_without_stock,
    offer_id: line.offer.id,
    quantity: line.quantity,
    is_sold_as_unit: line.variant.variant_is_sold_as_unit,
    measure_quantity: line.variant.variant_measure_quantity,
    unit_price_without_discount: line.offer.unit_price,
    per_measure_price_without_discount: line.offer.measurement_price,
  }))
  return {
    pre_shippings:
      storeCode === undefined
        ? undefined
        : [
            {
              kind: 'click_and_collect',
              store_code: storeCode,
              line_items: shoppingList.lines.filter((line) => line.click_and_collect?.store_code),
            },
          ],
    line_items: lineItems,
    offerIds: shoppingList.lines.map((line) => line.offer.id),
  }
}
