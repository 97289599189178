import { onMounted, onUnmounted, ref } from 'vue'

export default function useElementWidth(domElement) {
  const elementWidth = ref<number>(0)

  function onResize() {
    elementWidth.value = domElement.value.clientWidth
  }

  onMounted(() => {
    elementWidth.value = domElement.value.clientWidth
    window.addEventListener('resize', onResize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', onResize)
  })

  return elementWidth
}
