import { useAuthService } from '@b2ag/auth'
import { useFeatureFlipping } from '@b2ag/feature-flipping'
import { useAnalyticService } from '@b2ag/plugin-aladin-gtm'
import Sentry, { sentryIsEnabled } from '@b2ag/sentry'
import Vue from 'vue'
import Vuex from 'vuex'
import addressesStore from '@b2ag/store/src/addresses.store'
import stockStore from '@b2ag/store/src/stock.store'
import searchStore from '@b2ag/store/src/search.store'
import recommendationsStore from '@b2ag/store/src/recommendations.store'

import { userService } from '@/services'
import errors from '@/store/errors.store'
import membership from '@/store/membership.store'
import previsionnel from '@/store/previsionnel.store'
import shop from '@/store/shop/shop.store'
import shoppingList from '@/store/shopping-list.store'

Vue.use(Vuex)

export const MEMBERSHIP_LOCAL_STORAGE_KEY = 'targetCustomer'

export const defaultUser = {
  isLoading: false,
  informations: {},
  crops: [],
  cooperatives: [],
  profiles: [],
}
export const defaultCart = { line_items: [] }
export const defaultApp = {
  isOutdated: false,
  isOnline: true,
  isAuthenticated: false,
  isMobile: false,
}

const globalState = {
  App: defaultApp,
  user: defaultUser,
  /* ---------------------------- tech-specific ---------------------------- */
  targetCustomer: JSON.parse(localStorage.getItem(MEMBERSHIP_LOCAL_STORAGE_KEY) ?? '{}'),
}

export function setSentryUser(user, targetCustomer) {
  if (sentryIsEnabled) {
    if (!user || user === defaultUser) {
      Sentry.setUser(null)
      return
    }
    let targetMembership
    if (targetCustomer) {
      targetMembership = {
        id: targetCustomer.id,
        companyName: targetCustomer.company_name,
        membershipNumber: targetCustomer.membership_number,
        validCertiphyto: targetCustomer.valid_certiphyto,
        validExplosivesPrecursor: targetCustomer.valid_explosives_precursor,
      }
    }
    Sentry.setUser({
      id: user.uid,
      username: user.email,
      email: user.email,
      currentCooperativeId: user.technical_sale_information?.cooperative_id,
      targetMembership,
    })
  }
}

export function setSentryUserTags(user) {
  if (
    !user ||
    user === defaultUser ||
    window.env.SENTRY_ENABLED !== 'yes' ||
    !user.technical_sale_information?.cooperative_id ||
    !user.cooperatives?.length
  ) {
    return
  }
  const cooperativeName = user.cooperatives.find(
    (coop) => parseInt(coop.id, 10) === user.technical_sale_information.cooperative_id,
  )?.name
  if (cooperativeName) {
    Sentry.setTag('userCooperative', cooperativeName)
  }
}

export const mutations = {
  RESET_USER(state) {
    state.user = defaultUser
    setSentryUser(state.user, null)
    setSentryUserTags(state.user)
    state.App.isAuthenticated = false
  },
  SET_ONLINE_STATUS(state, status) {
    state.App.isOnline = status
  },
  SET_UPDATE_STATUS(state, status) {
    state.App.isOutdated = status
  },
  SET_USER_IS_LOADING(state, isLoading) {
    state.user.isLoading = isLoading
  },
  SET_APP_IS_MOBILE(state, isMobile) {
    state.App.isMobile = isMobile
  },
  SET_USER(state, user) {
    if (user) {
      state.user = {
        ...user,
      }
      setSentryUser(state.user, null)
      setSentryUserTags(state.user)
      if (state.user?.technical_sale_information?.cooperative_id) {
        useFeatureFlipping().updateFeatureFlipping(state.user.technical_sale_information.cooperative_id)
      }
    }
  },
  SET_USER_CGU_ACCEPTED_AT(state, acceptedAt) {
    Vue.set(state.user, 'tech_cgu_accepted_at', acceptedAt)
  },
  SET_IS_AUTHENTICATED(state, isAuthenticated) {
    Vue.set(state.App, 'isAuthenticated', isAuthenticated)
  },
  APP_INIT(_, getters) {
    useAnalyticService().trackEvent('app_init', { user: getters.analyticsUserPayload })
  },
  /* ---------------------------- tech-specific ---------------------------- */
  SET_CUSTOMER(state, customer) {
    setSentryUser(state.user, customer)
    setSentryUserTags(state.user)
    state.targetCustomer = { ...customer }
    localStorage.setItem(MEMBERSHIP_LOCAL_STORAGE_KEY, JSON.stringify(customer))
  },
}

export const mainGetters = {
  isOutdated(state) {
    return state.App.isOutdated
  },
  isMobile(state) {
    return state.App.isMobile
  },
  isOnline(state) {
    return state.App.isOnline
  },
  user(state) {
    return state.user
  },
  currentCooperative(state) {
    return state.user.cooperatives.find((cooperative) => {
      return parseInt(cooperative.id, 10) === state.user.technical_sale_information?.cooperative_id
    })
  },
  currentCooperativeId(state) {
    return state.user?.technical_sale_information?.cooperative_id
  },
  currentCooperativeName(state) {
    const partner = state.user.cooperatives.find((cooperative) => {
      return parseInt(cooperative.id, 10) === state.user.technical_sale_information?.cooperative_id
    })
    const cooperativeName = partner?.name
    return cooperativeName
  },
  isAuthenticated(state) {
    return state.App.isAuthenticated
  },
  isUserLoaded(state) {
    return state.user && state.user !== defaultUser
  },
  isFrontAgri(state) {
    return state.App.isFrontAgri
  },
  analyticsUserPayload(_, getters) {
    return {
      partner_id: getters.currentCooperativeId,
      partner_name: getters.currentCooperativeName,
      technical_sales_number: getters.user?.technical_sale_information?.uuid,
      tehcnical_sales_id: getters.user?.technical_sale_information?.id,
    }
  },
  /* ---------------------------- tech-specific ---------------------------- */
  targetCustomer(state) {
    // next line is only used to indicate to vuex to refresh the getter
    // without it, vuex will assume the getter targetCustomer haven't change
    // and will not return update value from the localStorage
    // eslint-disable-next-line no-void
    void state.targetCustomer
    return JSON.parse(localStorage.getItem(MEMBERSHIP_LOCAL_STORAGE_KEY))
  },
  targetCustomerMembership(_, getters) {
    return getters.targetCustomer.membership_number ?? getters.targetCustomer.membershipNumber
  },
  isCGUAccepted(state) {
    return !!state.user.tech_cgu_accepted_at
  },
  isTargetCustomerCropProtectionAllowed(_, getters) {
    return getters.targetCustomer.valid_certiphyto
  },
  isTargetCustomerExplosivesPrecursorAllowed(_, getters) {
    const isFeatureEnabled = window.env.EXPLOSIVES_PRECURSOR_FEATURE === 'yes'
    if (!isFeatureEnabled) {
      return true
    }
    return getters.targetCustomer.valid_explosives_precursor
  },
}

export const actions = {
  async fetchUser({ commit, getters }) {
    commit('SET_USER_IS_LOADING', true)
    const apiUser = await userService.get().catch(() => defaultUser)
    commit('SET_USER', apiUser)
    commit('SET_USER_IS_LOADING', false)
    const authService = useAuthService()
    const isAuthenticated = await authService.isAuthenticated()
    commit('SET_IS_AUTHENTICATED', isAuthenticated)
    commit('APP_INIT', getters)
  },
  setScannerVisibility({ commit }, isVisible) {
    commit('SET_SCANNER_VISIBILITY', isVisible)
  },
  setOnlineStatus({ commit }, status) {
    commit('SET_ONLINE_STATUS', status)
  },
  setUpdateAvailable({ commit }) {
    commit('SET_UPDATE_STATUS', true)
  },
  logout({ commit }) {
    commit('RESET_USER')
    commit('shop/RESET_SHOP')
    commit('addresses/setAddresses')
    commit('addresses/deleteCurrentAddress')

    const authService = useAuthService()
    authService.logout()
  },
  /* ---------------------------- tech-specific ---------------------------- */
  async setTargetCustomer(context, customer) {
    if (customer.id !== context.getters.targetCustomer?.id) {
      await context.dispatch('addresses/deleteCurrentAddress')
    }
    context.commit('SET_CUSTOMER', customer)
    await Promise.all([context.dispatch('shop/reloadProducts'), context.dispatch('addresses/fetchAddresses')])
  },
}

export const modules = {
  addresses: addressesStore,
  recommendation: recommendationsStore,
  search: searchStore,
  shop,
  membership,
  stock: stockStore,
  errors,
  shoppingList,
  previsionnel,
}

const store = new Vuex.Store({
  actions,
  getters: mainGetters,
  mutations,
  state: globalState,
  modules,
})

export const getStore = () => store

export default store
