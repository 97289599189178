import { MembershipService as MembershipDataSource } from '@b2ag/membership/src/services/membership.service'
import { createShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { useAuthService } from '@b2ag/auth'
import { getConfig } from '@b2ag/micro-front-tools'
import { createTargetMembershipRepository } from './infra/target-membership.repository'
import { createChangeTargetMembershipUsecase } from './usecases/change-target-membership.usecase'
import { createCreateTargetShoppingListUsecase } from './usecases/create-target-shopping-list.usecase'
import { createFetchDefaultTargetMembershipUsecase } from './usecases/fetch-default-target-membership.usecase'
import { createFetchTargetMembershipUsecase } from './usecases/fetch-target-membership.usecase'
import { createFetchTargetShoppingListUsecase } from './usecases/fetch-target-shopping-list.usecase'

const shoppingListDataSource = createShoppingListApiClient({
  shoppingListApiHost: getConfig('SHOPPING_LIST_API_HOST'),
  useCartV2: true,
  accessTokenSource: () => useAuthService().getAccessToken(),
})

const membershipDataSource = new MembershipDataSource({ userApiHost: getConfig('USER_API_HOST') })

const targetMembershipRepository = createTargetMembershipRepository({
  membershipDataSource,
  shoppingListDataSource,
})

const fetchTargetMembershipUsecase = createFetchTargetMembershipUsecase({
  targetMembershipRepository,
})

const createTargetShoppingListUsecase = createCreateTargetShoppingListUsecase({
  targetMembershipRepository,
})

const changeTargetMembershipUsecase = createChangeTargetMembershipUsecase({
  targetMembershipRepository,
})

const fetchDefaultTargetMembershipUsecase = createFetchDefaultTargetMembershipUsecase({
  targetMembershipRepository,
})

const fetchTargetShoppingListUsecase = createFetchTargetShoppingListUsecase({
  targetMembershipRepository,
})

export {
  changeTargetMembershipUsecase,
  createTargetShoppingListUsecase,
  fetchDefaultTargetMembershipUsecase,
  fetchTargetMembershipUsecase,
  fetchTargetShoppingListUsecase,
}
