import type { TargetMembership } from '../domain/target-membership.interface'
import type { TargetMembershipRepository } from '../infra/target-membership.repository'

export interface FetchTargetMembershipUsecase {
  execute: (partnerId: number, membershipNumber: string, shoppingListId?: string) => Promise<TargetMembership>
}

interface Dependencies {
  targetMembershipRepository: TargetMembershipRepository
}

export function createFetchTargetMembershipUsecase(deps: Dependencies): FetchTargetMembershipUsecase {
  async function execute(partnerId, membershipNumber) {
    return await deps.targetMembershipRepository.fetchTargetMembership(partnerId, membershipNumber)
  }

  return { execute }
}
