import type { Store } from '@b2ag/store/src/interfaces/store.interface'
import { type Ref, ref } from 'vue'
import { fetchDefaultTargetMembershipUsecase } from '@b2ag/target-membership/src/dependency-injection'
import { useShoppingListReactions } from '@b2ag/shopping-list/src/composables/useShoppingListReactions'
import { membershipDataSource } from '../dependency-injection'
import { fetchLastShoppingListForMembershipUseCase } from '../contexts/product/dependency-injection'
import type { MembershipChangedParams } from './useMembershipReactions'
import { useMembershipReactions } from './useMembershipReactions'

export const MEMBERSHIP_NUMBER_LOCAL_STORAGE_KEY = 'targetMembershipNumber'
export const SHOPPING_LIST_LOCAL_STORAGE_KEY = 'targetShoppingListId'

export function createCurrentMembership(store: Store): CurrentMembership {
  /**
   * SLR:
   * Cette partie n'est pas triviale.
   * Beaucoup de changements d'états sont déclenchés via ces deux variables.
   * Seulement, le localStorage va de facto casser la réactivité...
   *
   * Option :
   * Synchroniser les stores dans le localStorage
   * (en commençant par shoppingList et membership)
   *  Pros:
   *    - assure la rétrocompatibilité
   *    - persistance des données
   *    - possibilité de recréer le store vuex depuis le localStorage
   *    - les components, composables appelent le store, ils ne soucient plus du localStorage
   *  Cons:
   *    - plus complexe à mettre en place
   */
  const membershipNumber = ref<string | null>(localStorage.getItem(MEMBERSHIP_NUMBER_LOCAL_STORAGE_KEY))
  const shoppingListId = ref<string | null>(localStorage.getItem(SHOPPING_LIST_LOCAL_STORAGE_KEY))

  async function loadCurrentMembership() {
    if (localStorage.getItem(MEMBERSHIP_NUMBER_LOCAL_STORAGE_KEY)) {
      await updateCurrentMembershipNumber(localStorage.getItem(MEMBERSHIP_NUMBER_LOCAL_STORAGE_KEY) as string)
      await updateCurrentShoppingListId({
        shoppingListId: localStorage.getItem(SHOPPING_LIST_LOCAL_STORAGE_KEY) as string,
      })
    } else {
      const defaultTargetMembership = await fetchDefaultTargetMembershipUsecase.execute(
        store.getters.currentCooperative.id,
        store.getters.user.technical_sale_information.uuid,
      )
      if (defaultTargetMembership) {
        await updateCurrentMembershipNumber(defaultTargetMembership.membership.membershipNumber)
        await updateCurrentShoppingListId({ shoppingListId: defaultTargetMembership.shoppingList?.id || null })
      }
    }
  }

  async function updateCurrentMembershipNumber(params: MembershipChangedParams) {
    membershipNumber.value = params
    setCurrentMembershipNumberInLocalStorage(params)
    await setLegacyTargetCustomerInStore(params, store.getters.currentCooperative.id)
  }

  function updateCurrentShoppingListId(params: { shoppingListId: string | null }) {
    // @TODO Maybe recreate a reaction for ShoppingListChanged / ShoppingListSwitched
    shoppingListId.value = params.shoppingListId
    if (params.shoppingListId !== null) {
      localStorage.setItem(SHOPPING_LIST_LOCAL_STORAGE_KEY, params.shoppingListId)
    } else {
      localStorage.removeItem(SHOPPING_LIST_LOCAL_STORAGE_KEY)
    }
  }

  async function loadLastPendingShoppingList() {
    if (!membershipNumber.value) return
    const shoppingList = await fetchLastShoppingListForMembershipUseCase(membershipNumber.value)
    updateCurrentShoppingListId({ shoppingListId: shoppingList?.id || null })
  }

  function initCurrentMembership() {
    useShoppingListReactions().provideSent(loadLastPendingShoppingList)
    useShoppingListReactions().provideRemoved(loadLastPendingShoppingList)

    useMembershipReactions().provide({
      async processChanged(params: MembershipChangedParams): Promise<void> {
        updateCurrentMembershipNumber(params)
      },
    })
  }

  function setCurrentMembershipNumberInLocalStorage(currentMembershipNumber: string) {
    localStorage.setItem(MEMBERSHIP_NUMBER_LOCAL_STORAGE_KEY, currentMembershipNumber)
  }

  async function setLegacyTargetCustomerInStore(currentMembershipNumber: string, partnerId: number) {
    const customer = await membershipDataSource.get(partnerId, currentMembershipNumber)
    await store.dispatch('setTargetCustomer', customer)
  }

  initCurrentMembership()

  return {
    membershipNumber,
    shoppingListId,
    initCurrentMembership,
    loadCurrentMembership,
    updateCurrentMembershipNumber,
    updateCurrentShoppingListId,
    setCurrentMembershipNumberInLocalStorage,
  }
}

export interface CurrentMembership {
  membershipNumber: Ref<string | null>
  shoppingListId: Ref<string | null>
  initCurrentMembership: () => void
  loadCurrentMembership: () => Promise<void>
  updateCurrentMembershipNumber: (params: MembershipChangedParams) => Promise<void>
  updateCurrentShoppingListId: (params: { shoppingListId: string | null }) => void
  setCurrentMembershipNumberInLocalStorage: (currentMembershipNumber: string) => void
}

let currentMembership: CurrentMembership

export function provideCurrentMembership(store) {
  currentMembership = createCurrentMembership(store)
  return currentMembership
}

export function useCurrentMembership(): CurrentMembership {
  if (!currentMembership) {
    throw new Error('Current membership was not initialized.')
  }
  return currentMembership
}
