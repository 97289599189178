// import { useLogger } from '@/composables/useLogger'
import { useLogger } from '@b2ag/composables/src/useLogger'
import { createShoppingListApiClient } from '@aladin/shared-shopping-list-sdk'
import { useAuthService } from '@b2ag/auth'
import { getConfig } from '@b2ag/micro-front-tools'
import { createShoppingListRepository } from './infra/shopping-list.repository'
import { createAddLineToShoppingListUsecase } from './usecases/add-line-to-shopping-list'
import { createFetchLastShoppingListForMembershipUseCase } from './usecases/fetch-last-shopping-list-for-membership'

export const logger = useLogger('product')

export const shoppingListDatasource = createShoppingListApiClient({
  shoppingListApiHost: getConfig('SHOPPING_LIST_API_HOST'),
  useCartV2: true,
  accessTokenSource: () => useAuthService().getAccessToken(),
  logger,
})

export const shoppingListRepository = createShoppingListRepository({
  shoppingListDatasource,
  logger,
})

export const fetchLastShoppingListForMembershipUseCase = createFetchLastShoppingListForMembershipUseCase({
  shoppingListRepository,
  logger,
})

export const addLineToShoppingListUseCase = createAddLineToShoppingListUsecase({
  shoppingListRepository,
  logger,
})
