import { filterAllowedLogisticOffers } from '@b2ag/stock'
import type { CooperativeProduct } from '@b2ag/product/src/services/product'
import { Offer, variantId } from '@b2ag/offers/src/offer'
import { Commit, RootGetters } from '@b2ag/store'
import { shopMutations, type Context, type ShopState } from '@b2ag/store/src/shop.store'
import { discountService, offerService, productService } from '@/services'
import groupBy from '@b2ag/utils/src/group-by'

export function getMissingProductsIds(
  productIdList: string[],
  alreadyLoadedProductIds: string[],
  productsForPrice: Set<string>,
) {
  const alreadyLoadedProductIdsSet = new Set(alreadyLoadedProductIds)
  return productIdList.filter(
    (productId) => !alreadyLoadedProductIdsSet.has(productId) || productsForPrice.has(productId),
  )
}

export function setAlwaysCoopProduct(product: CooperativeProduct) {
  return Object.assign(product, { isCooperativeProduct: true })
}

export async function basicFetchProducts(productIdList: string[], cooperativeId: number) {
  const products = await productService.findProducts(productIdList, cooperativeId)
  return products.map(setAlwaysCoopProduct)
}

export function extractCoopAndMembership(rootGetters: Record<RootGetters, any>) {
  const { currentCooperativeId, targetCustomerMembership } = rootGetters

  if (!currentCooperativeId || !targetCustomerMembership) {
    const message = `Data cannot be loaded without cooperativeId (currentValue = ${currentCooperativeId}) and targetCustomerMembership = (currentValue = ${targetCustomerMembership})`
    throw new Error(message)
  }

  return { currentCooperativeId, targetCustomerMembership }
}

export async function commonFetchProducts(
  { commit, dispatch, getters, rootGetters, state }: Context,
  currentCooperativeId,
  productsAndOffers: Promise<[CooperativeProduct[], Offer[]]>,
) {
  try {
    commit('START_SHOP_LOADING')
    commit('START_OFFERS_LOADING')
    commit('START_DISCOUNTS_LOADING')
    const [products, offers] = await productsAndOffers
    if (!products.length) {
      commit('STOP_OFFERS_LOADING')
      commit('STOP_DISCOUNTS_LOADING')
      return
    }

    products.forEach(setAlwaysCoopProduct)
    await dispatch('fetchCooperativeStores')

    const variants = products.flatMap((product) => product.variants)
    filterAllowedLogisticOffers(getters.getCooperativeStores, variants, offers)
    commit('SET_PRODUCT_MAP', { productList: products })
    commit('SET_OFFER_LIST_BY_VARIANT', groupBy(variantId, offers))

    commit('STOP_OFFERS_LOADING')
    const discountList = await discountService.findDiscounts(
      offers.map((offer) => offer.offerId).concat(await getCartOfferIds(rootGetters, state)),
      currentCooperativeId,
    )
    commit('SET_DISCOUNT_MAP', discountList)
    commit('STOP_DISCOUNTS_LOADING')
  } catch (e: any) {
    commit('RESET_SHOP')
    throw e
  } finally {
    commit('STOP_SHOP_LOADING')
  }
}

export async function getCartOfferIds(
  rootGetters: Record<RootGetters, any>,
  state: Pick<ShopState, 'cartOfferIdsPromise' | 'resolveCartPromise'>,
) {
  if (rootGetters['shoppingList/isListInitialized']) return rootGetters['shoppingList/initialOfferIds']
  state.cartOfferIdsPromise = new Promise((resolve) => {
    state.resolveCartPromise = resolve
  })
  const cartOfferIds = await state.cartOfferIdsPromise
  state.cartOfferIdsPromise = undefined
  return cartOfferIds
}

export async function simpleFetchProducts(
  commit: Commit<typeof shopMutations>,
  cooperativeId: number,
  targetCustomerMembership: string,
  productIdList: string[],
) {
  if (!cooperativeId || !targetCustomerMembership) {
    const message = `Products with ids ${productIdList} can not be loaded without cooperativeId (currentValue = ${cooperativeId}) and targetCustomerMembership = (currentValue = ${targetCustomerMembership})`
    throw new Error(message)
  }
  try {
    const [products, offers] = await Promise.all([
      basicFetchProducts(productIdList, cooperativeId),
      offerService.findOffers(productIdList, cooperativeId, targetCustomerMembership),
    ])
    commit('SET_PRODUCT_MAP', { productList: products })
    commit('SET_OFFER_LIST_BY_VARIANT', groupBy(variantId, offers))
    const discountList = await discountService.findDiscounts(
      offers.map((offer) => offer.offerId),
      cooperativeId,
    )
    commit('SET_DISCOUNT_MAP', discountList)
  } catch (e: any) {
    commit('RESET_SHOP')
    throw e
  }
}
