import type { Logger } from '@aladin/shared-logger'
import type { ShoppingListRef } from '@b2ag/shopping-list/src/domain/shopping-list.model'
import type { ShoppingListRepository } from '../infra/shopping-list.repository'

export function createFetchLastShoppingListForMembershipUseCase(
  deps: Dependencies,
): FetchLastShoppingListForMembershipUseCase {
  const { shoppingListRepository, logger } = deps
  return async (membershipNumber: string): Promise<ShoppingListRef | null> => {
    logger.debug('fetchLastShoppingListForMembership %s', membershipNumber)
    return await shoppingListRepository.fetchLastShoppingListForMembership(membershipNumber)
  }
}

export interface FetchLastShoppingListForMembershipUseCase {
  (membershipId: string): Promise<ShoppingListRef | null>
}

interface Dependencies {
  shoppingListRepository: ShoppingListRepository
  logger: Logger
}
