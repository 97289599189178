import { createAxiosClientWithAladinBearer } from '@b2ag/axios'
import type { AxiosInstance } from '@b2ag/axios'
import { buildCallerId } from '@b2ag/utils/src/caller-id'
import { mapFromApi as mapCooperativeFromApi } from './cooperative.mapper'
import { mapFromApi as mapProfileFromApi } from './profile.mapper'

function formatResponseData(data) {
  const { cooperatives = [], profiles = [], ...others } = data
  return {
    ...others,
    cooperatives: cooperatives.map(mapCooperativeFromApi),
    profiles: profiles.map(mapProfileFromApi),
  }
}
export interface TechnicalSaleInformation {
  cooperative_id: number
  first_name: string
  last_name: string
  uuid: string
}

class UserService {
  private api: AxiosInstance

  constructor(userApiHost: string) {
    this.api = createAxiosClientWithAladinBearer(`${userApiHost}/v1/users`, { callerId: buildCallerId() })
  }

  async get() {
    return formatResponseData((await this.api.get('/me')).data)
  }

  async put(data) {
    if (!data) return false
    const dataToSend = { ...data }

    return formatResponseData((await this.api.put('/me', dataToSend)).data)
  }
}

export default UserService
