import { userService } from '@/services'
import { getStore } from '@/store/store'
import { messagesForParent } from '@b2ag/micro-front-tools'
import type { DataForParent } from '@b2ag/micro-front-tools'
import type { VueRouter } from 'vue-router/types/router'

function callbackAcceptCGU(router: VueRouter) {
  const store = getStore()

  userService
    .put({ has_accepted_tech_cgu: true })
    .then((userApi) => store.commit('SET_USER_CGU_ACCEPTED_AT', userApi.tech_cgu_accepted_at))
    .then(() => router.push({ name: 'home' }))
}

export function callbacksFromLegalsMicroFrontIframe(data: DataForParent, router: VueRouter) {
  switch (data.message) {
    case messagesForParent.ACCEPT_CGU_DONE:
      callbackAcceptCGU(router)
      break
    default:
      break
  }
}
